import React, { useState } from "react";
import styled from "styled-components";

// Styled Components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ open }) => (open ? "block" : "none")};
  z-index: 100;
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -5%);
  width: ${({ width }) => width || "fit-content"};
  height: ${({ height }) => height || "fit-content"};
  background-color: var(--color-background-light);
  border-radius: 4px;
  z-index: 101;
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
`;

const ModalTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const ModalContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid #ccc;
`;

// Modal Component
const Modal = ({
  title,
  children,
  width,
  height,
  showmodal,
  setshowmodal,
  showFoter = true,
}) => {
  return (
    <>
      {showmodal && (
        <>
          <ModalOverlay open={showmodal} onClick={() => setshowmodal(false)} />
          <ModalContainer open={showmodal} width={width} height={height}>
            <ModalContent>{children}</ModalContent>
            {showFoter && (
              <ModalFooter>
                <button onClick={() => setshowmodal(false)}>Close</button>
              </ModalFooter>
            )}
          </ModalContainer>
        </>
      )}
    </>
  );
};

export default Modal;
