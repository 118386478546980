// Import necessary modules and hooks
import React from "react";
import ContainerButton from "../../stories/ContainerButton";
import fetchApi from "../Lib/api";
import useStartupStore from "../../stores/startupStore";
import useUserStore from "../../stores/userStore";
import { Loader } from "@mantine/core";

// Safely parse JSON data
function parseJSON(data) {
  try {
    return typeof data === "string" ? JSON.parse(data) : data || { blocks: [] };
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return { blocks: [] };
  }
}

// Check if a block already has initials
function blockHasInitials(block) {
  if (!block || !block.data) return false;

  function containsInitials(data) {
    if (
      typeof data === "object" &&
      data.type === "editor-block-user-initials"
    ) {
      return true;
    }
    if (typeof data === "string") {
      return /<sup><a[^>]*>\(([^)]+)\)<\/a><\/sup>/.test(data);
    }
    if (Array.isArray(data)) {
      return data.some(containsInitials);
    }
    if (typeof data === "object" && data !== null) {
      return Object.values(data).some(containsInitials);
    }
    return false;
  }

  return containsInitials(block.data);
}

// Create an editor block for user initials
function createUserInitialsEditorBlock(userInitials, userUrl) {
  return {
    type: "editor-block-user-initials",
    data: {
      initials: userInitials,
      url: userUrl,
    },
  };
}

// Add initials to block data (only if the block doesn't already have initials)
function addUserInitialsToBlockData(data, userInitials, userUrl) {
  if (typeof data === "string") {
    if (/<sup><a[^>]*>\(([^)]+)\)<\/a><\/sup>/.test(data)) {
      return data;
    }
    return [
      { type: "paragraph", data: data },
      createUserInitialsEditorBlock(userInitials, userUrl),
    ];
  }

  if (Array.isArray(data)) {
    if (data.some((item) => item.type === "editor-block-user-initials")) {
      return data;
    }
    return [...data, createUserInitialsEditorBlock(userInitials, userUrl)];
  }

  if (typeof data === "object" && data !== null) {
    if (data.type === "editor-block-user-initials") {
      return data;
    }
    return {
      ...data,
      initials: createUserInitialsEditorBlock(userInitials, userUrl),
    };
  }

  return data;
}

// Check if two blocks are identical (ignoring user initials)
function isBlockIdenticalIgnoringInitials(oldBlock, newBlock) {
  const sanitizeBlock = (block) => {
    const sanitized = { ...block };
    if (sanitized.data && Array.isArray(sanitized.data)) {
      sanitized.data = sanitized.data.filter(
        (item) => item.type !== "editor-block-user-initials"
      );
    }
    return sanitized;
  };

  return (
    JSON.stringify(sanitizeBlock(oldBlock)) ===
    JSON.stringify(sanitizeBlock(newBlock))
  );
}

// Add initials only to new or modified blocks
function addUserInitialsToNewData(oldData, newData, userInitials, userUrl) {
  const updatedBlocks = newData.blocks.map((newBlock, index) => {
    const oldBlock = oldData.blocks[index];

    // Check if the block is new or modified (ignoring initials)
    const isNewBlock = !oldBlock;
    const isModifiedBlock =
      oldBlock && !isBlockIdenticalIgnoringInitials(oldBlock, newBlock);

    // Only add initials to new or modified blocks
    if ((isNewBlock || isModifiedBlock) && !blockHasInitials(newBlock)) {
      newBlock.data = addUserInitialsToBlockData(
        newBlock.data,
        userInitials,
        userUrl
      );
    }

    return newBlock;
  });

  return {
    ...newData,
    blocks: updatedBlocks,
  };
}

// Main SaveComponent for saving the annotated data
const SaveComponent = ({ close, setMessage, loading, setLoading }) => {
  const {
    content,
    privacy,
    files,
    isOwner,
    selectedProgram,
    setProgram,
    program,
  } = useStartupStore((state) => ({
    content: state.content,
    privacy: state.privacy,
    files: state.files,
    isOwner: state.isOwner,
    selectedProgram: state.selectedProgram,
    setProgram: state.setProgram,
    program: state.program,
  }));

  const showTemporaryMessage = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  const {
    user: { id, first_name, last_name },
  } = useUserStore((state) => state.user);
  const userInitials = `${first_name[0]}${last_name[0]}`;
  const userUrl = `/profile/user/${id}`;

  const handleSave = async () => {
    const contentTouched =
      content[selectedProgram.cid]?.[selectedProgram.sid]?.touched;
    const hasFiles =
      files[selectedProgram.cid]?.[selectedProgram.sid]?.length > 0;
    const privacyChanged =
      program?.categories[selectedProgram.cid]?.sub_categories[
        selectedProgram.sid
      ].privacy !== privacy[selectedProgram.cid]?.[selectedProgram.sid];

    if (!contentTouched && !hasFiles && !privacyChanged) return;

    setLoading(true);

    const id =
      program?.categories[selectedProgram.cid]?.sub_categories[
        selectedProgram.sid
      ].id;
    const oldDataRaw =
      program?.categories[selectedProgram.cid]?.sub_categories[
        selectedProgram.sid
      ].response || '{ "blocks": [] }';
    const newDataRaw = content[selectedProgram.cid]?.[selectedProgram.sid]
      .response || { blocks: [] };

    const oldData = parseJSON(oldDataRaw);
    const newData =
      typeof newDataRaw === "string" ? parseJSON(newDataRaw) : newDataRaw;

    // Process newData to include user initials in new or modified blocks
    const newDataWithInitials = addUserInitialsToNewData(
      oldData,
      newData,
      userInitials,
      userUrl
    );

    try {
      const formData = new FormData();

      files[selectedProgram.cid]?.[selectedProgram.sid]?.forEach((file) => {
        if (!file.file) {
          formData.append(
            "sub_category[file_records_attributes][]",
            JSON.stringify({
              name: file.name,
              url: file.url,
              file_id: file.id,
              size_bytes: file.size_bytes,
              icon: file.icon,
              source: file.source,
            })
          );
        } else {
          formData.append("sub_category[files][]", file.file);
        }
      });
      formData.append(
        "sub_category[privacy]",
        privacy[selectedProgram.cid]?.[selectedProgram.sid]
      );

      if (isOwner) {
        formData.append(
          "sub_category[response]",
          JSON.stringify(newDataWithInitials)
        );
      } else {
        formData.append(
          "sub_category[proposed_response]",
          JSON.stringify(newDataWithInitials)
        );
      }

      const response = await fetchApi(
        `/sub_categories/${id}`,
        "PATCH",
        formData,
        true
      );
      if (response.ok) {
        const responseData = await response.json();
        setProgram(responseData.program);
        showTemporaryMessage("Changes saved successfully!");
        close();
      } else {
        showTemporaryMessage("Failed to save changes. Please try again.");
        console.error("HTTP error! Failed to submit form");
      }
    } catch (error) {
      console.error("Error:", error.message);
      showTemporaryMessage("An error occurred while saving. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <Loader
        className="mx-auto"
        color="indigo"
        size="lg"
        variant="dots"
      />
  ) : (
    <ContainerButton action={handleSave} buttonText="Save & Complete" />
  );
};

export default SaveComponent;
