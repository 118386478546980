import React from "react";
import styled from "styled-components";

// Styled components for different block types
const Paragraph = styled.p`
  color: #4a4a4a;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1em;
`;

const Header = styled.div`
  color: #2c3e50;
  margin-bottom: 0.5em;
  font-size: ${(props) => {
    switch (props.level) {
      case 1:
        return "2em";
      case 2:
        return "1.75em";
      case 3:
        return "1.5em";
      case 4:
        return "1.25em";
      case 5:
        return "1em";
      case 6:
        return "0.875em";
      default:
        return "1em";
    }
  }};
`;
const List = styled.ul`
  list-style-type: disc;
  margin-left: 1.5em;
  margin-bottom: 1em;
`;

const OrderedList = styled.ol`
  list-style-type: decimal;
  margin-left: 1.5em;
  margin-bottom: 1em;
`;

const ListItem = styled.li`
  margin-bottom: 0.5em;
`;

const ImageWrapper = styled.figure`
  margin: 1em 0;
  text-align: center;
`;

const Image = styled.img`
  max-width: 100%;
  border-radius: 8px;
`;

const Figcaption = styled.figcaption`
  margin-top: 0.5em;
  color: #7f8c8d;
  font-size: 0.9em;
`;

const CodeBlock = styled.pre`
  background-color: #f8f7fa;
  color: #41314e;
  padding: 1em;
  border-radius: 8px;
  overflow-x: auto;
  margin-bottom: 1em;
  font-family: "Courier New", Courier, monospace;
`;

const EmbedWrapper = styled.div`
  margin: 1em 0;
  text-align: center;
`;

const LinkPreview = styled.div`
  display: flex;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 1em;
  text-decoration: none;
  color: inherit;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const LinkImage = styled.div`
  flex: none;
  width: 120px;
  background-size: cover;
  background-position: center;
`;

const LinkContent = styled.div`
  padding: 1em;
  flex: 1;
`;

const LinkTitle = styled.h5`
  margin: 0 0 0.5em 0;
  font-size: 1em;
  color: #2c3e50;
`;

const LinkDescription = styled.p`
  margin: 0 0 1em 0;
  font-size: 0.9em;
  color: #7f8c8d;
`;

const LinkButton = styled.a`
  color: #3498db;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    color: #2980b9;
  }
`;

const FileAttachment = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
  padding: 1em;
  border-radius: 8px;
  margin-bottom: 1em;
`;

const FileIcon = styled.div`
  flex: none;
  width: 50px;
  height: 50px;
  background-color: ${(props) => props.bgColor || "#aa2284"};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 1em;
  font-weight: bold;
`;

const FileInfo = styled.div`
  flex: 1;
`;

const FileTitle = styled.div`
  font-size: 1em;
  margin-bottom: 0.5em;
`;

const FileSize = styled.div`
  font-size: 0.9em;
  color: #7f8c8d;
`;

const DownloadButton = styled.a`
  color: #3498db;
  text-decoration: none;
  font-weight: bold;
  margin-left: auto;

  &:hover {
    color: #2980b9;
  }
`;

const EditorContentDisplay = ({ content }) => {
  let parsedContent;

  // Parse the content JSON
  if (typeof content === "string") {
    try {
      parsedContent = JSON.parse(content);
    } catch (e) {
      console.error("Invalid JSON content", e);
      return null;
    }
  } else {
    parsedContent = content;
  }

  const renderInitials = (block) => {
    if (block.data.initials) {
      return (
        <sup>
          <a
            href={block.data.initials.data.url}
            contentEditable={false}
            style={{
              textDecoration: "none",
              color: "blue",
              marginInlineStart: "4px",
              marginBlockStart: "2px",
            }}
          >
            ({block.data.initials.data.initials})
          </a>
        </sup>
      );
    }
    return null;
  };

  const renderBlock = (block, index) => {
    switch (block.type) {
      case "paragraph":
        return (
          <Paragraph key={index} style={{ marginBottom: "1rem" }}>
            <span dangerouslySetInnerHTML={{ __html: block.data.text }} />
            {renderInitials(block)}
          </Paragraph>
        );

        case "header":
          const HeaderTag = `h${block.data.level}`;
          const headerStyles = {
            1: { fontSize: "2em", marginBottom: "1rem" },
            2: { fontSize: "1.75em", marginBottom: "1rem" },
            3: { fontSize: "1.5em", marginBottom: "1rem" },
            4: { fontSize: "1.25em", marginBottom: "1rem" },
            5: { fontSize: "1em", marginBottom: "1rem" },
            6: { fontSize: "0.875em", marginBottom: "1rem" },
          };
    
          return (
            <div key={index} style={headerStyles[block.data.level]}>
              <HeaderTag dangerouslySetInnerHTML={{ __html: block.data.text }} />
              {renderInitials(block)}
            </div>
          );

      case "list":
        const ListTag = block.data.style === "ordered" ? OrderedList : List;
        return (
          <ListTag key={index} style={{ marginBottom: "1rem" }}>
            {block.data.items.map((item, idx) => (
              <ListItem key={idx}>
                <span dangerouslySetInnerHTML={{ __html: item }} />
                {idx === block.data.items.length - 1 && renderInitials(block)}
              </ListItem>
            ))}
          </ListTag>
        );

      case "image":
        return (
          <ImageWrapper key={index} style={{ marginBottom: "1rem" }}>
            <Image src={block.data.file.url} alt={block.data.caption || ""} />
            {block.data.caption && (
              <Figcaption>{block.data.caption}</Figcaption>
            )}
            {renderInitials(block)}
          </ImageWrapper>
        );

      case "code":
        return (
          <CodeBlock key={index} style={{ marginBottom: "1rem" }}>
            {block.data.code}
            {renderInitials(block)}
          </CodeBlock>
        );

      case "embed":
        return (
          <EmbedWrapper key={index} style={{ marginBottom: "1rem" }}>
            <iframe
              src={block.data.embed}
              width={block.data.width || "100%"}
              height={block.data.height || "400"}
              frameBorder="0"
              allowFullScreen
              title={block.data.caption || "Embedded content"}
            ></iframe>
            {block.data.caption && (
              <Figcaption>{block.data.caption}</Figcaption>
            )}
            {renderInitials(block)}
          </EmbedWrapper>
        );

      case "linkTool":
        const data = block.data;
        const meta = data.meta;
        return (
          <LinkPreview
            key={index}
            as="a"
            href={data.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginBottom: "1rem" }}
          >
            <LinkImage
              style={{ backgroundImage: `url(${meta.image?.url})` }}
              alt={meta.title}
            />
            <LinkContent>
              <LinkTitle>{meta.title}</LinkTitle>
              <LinkDescription>{meta.description}</LinkDescription>
              <LinkButton
                href={data.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit Website
              </LinkButton>
            </LinkContent>
          </LinkPreview>
        );

      case "attaches":
        const fileData = block.data.file;
        const title = block.data.title || fileData.name;
        const extensionColor = "#aa2284"; // Customize based on file type if needed
        const fileSize = (fileData.size / 1024).toFixed(1); // Size in KiB

        return (
          <FileAttachment key={index} style={{ marginBottom: "1rem" }}>
            <FileIcon bgColor={extensionColor}>
              {fileData.extension.toUpperCase()}
            </FileIcon>
            <FileInfo>
              <FileTitle>{title}</FileTitle>
              <FileSize>{fileSize} KiB</FileSize>
            </FileInfo>
            <DownloadButton
              href={fileData.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </DownloadButton>
          </FileAttachment>
        );

      case "table":
        return (
          <div
            key={index}
            className="overflow-x-auto"
            style={{ marginBottom: "1rem" }}
          >
            <table className="w-full border-collapse border border-gray-200">
              {block.data.withHeadings && (
                <thead>
                  <tr>
                    {block.data.content[0].map((heading, headingIndex) => (
                      <th
                        key={headingIndex}
                        className="border border-gray-200 p-2 bg-gray-100 font-semibold text-left"
                      >
                        {heading}
                      </th>
                    ))}
                  </tr>
                </thead>
              )}
              <tbody>
                {block.data.withHeadings
                  ? block.data.content.slice(1).map((row, rowIndex) => (
                      <tr key={rowIndex} className="even:bg-gray-50">
                        {row.map((cell, cellIndex) => (
                          <td
                            key={cellIndex}
                            className="border border-gray-200 p-2"
                          >
                            {cell}
                          </td>
                        ))}
                      </tr>
                    ))
                  : block.data.content.map((row, rowIndex) => (
                      <tr key={rowIndex} className="even:bg-gray-50">
                        {row.map((cell, cellIndex) => (
                          <td
                            key={cellIndex}
                            className="border border-gray-200 p-2"
                          >
                            {cell}
                          </td>
                        ))}
                      </tr>
                    ))}
              </tbody>
            </table>
            {renderInitials(block)}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      {parsedContent?.blocks?.map((block, index) => renderBlock(block, index))}
    </div>
  );
};

export default EditorContentDisplay;
