import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import fetchApi from "./api"; // Adjust based on your structure
import useUserStore from "../../stores/userStore"; // Zustand store
import { Loader } from "@mantine/core";

// Function to fetch user data
const fetchUser = async (setUser) => {
  const response = await fetchApi("/api/users/current");
  if (!response.ok) {
    throw new Error("Failed to fetch user data");
  }
  const res = await response.json();
  setUser(res);
  console.log(res)
  return res;
};

// Function to update the last seen status
const updateLastSeen = async () => {
  try {
    const response = await fetchApi("/api/users/update_last_seen", "POST");
    if (response.ok) {
      console.log("Last seen updated");
    } else {
      console.log("Error updating last seen");
    }
  } catch (error) {
    console.error("Error during updateLastSeen:", error);
  }
};

const UserProvider = ({ children, allowUnauthenticated = false }) => {
  const { setUser, setUpdateInterval, clearUpdateInterval } = useUserStore();

  // Use TanStack Query to fetch the user data
  const { data, error, isLoading, isSuccess, isFetching } = useQuery({
    queryKey: ["user"],
    queryFn: () => fetchUser(setUser),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retryOnMount: false,
    retry: allowUnauthenticated ? false : 3,
  });

  // Set up the interval to update "last seen"
  useEffect(() => {
    if (!isFetching && isSuccess && data) {
      // Check for successful data fetch
      setUser(data); // Store the fetched user in Zustand
      setUpdateInterval(updateLastSeen);
    }
  }, [isFetching, isSuccess, data, setUser, setUpdateInterval]);

  // Clean up interval on unmount
  useEffect(() => {
    return () => clearUpdateInterval();
  }, [clearUpdateInterval]);

  if (isLoading || isFetching)
    return (
      <Loader
        className="mx-auto mt-10"
        color="indigo"
        size="lg"
        variant="dots"
      />
    );
  
    if (error && !allowUnauthenticated) {
      return <p>Error fetching user: {error.message}</p>;
    }

  return <>{children}</>;
};

export default UserProvider;