import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const LanguageDropdown = () => {
  const { i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Retrieve the language from localStorage or default to 'en'
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("appLanguage") || i18n.language || "en"
  );

  useEffect(() => {
    // Apply the language on component mount
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setSelectedLanguage(lang);
    localStorage.setItem("appLanguage", lang); // Save to localStorage
    setDropdownOpen(false);
  };

  const languages = [
    { code: "en", label: "English" },
    { code: "fr", label: "Français" },
    { code: "es", label: "Español" },
  ];

  return (
    <div className="m-1 relative inline-flex">
      <button
        id="hs-dropdown-item-checkbox"
        type="button"
        onClick={toggleDropdown}
        className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
        aria-haspopup="menu"
        aria-expanded={dropdownOpen ? "true" : "false"}
        aria-label="Dropdown"
      >
        {languages.find((lang) => lang.code === selectedLanguage)?.label}
        <svg
          className={`hs-dropdown-open:rotate-180 size-4 ${
            dropdownOpen ? "rotate-180" : ""
          }`}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="m6 9 6 6 6-6" />
        </svg>
      </button>

      {dropdownOpen && (
        <div
          className="absolute transition-[opacity,margin] duration-200 opacity-100 min-w-60 bg-white shadow-md rounded-lg p-1 space-y-0.5 mt-2 dark:bg-neutral-800 dark:border dark:border-neutral-700"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="hs-dropdown-item-checkbox"
        >
          {languages.map((language) => (
            <div
              key={language.code}
              className="relative flex items-start py-2 px-3 rounded-lg hover:bg-gray-100 dark:hover:bg-neutral-700"
              onClick={() => changeLanguage(language.code)}
            >
              <div className="flex items-center h-5 mt-1">
                <input
                  id={`radio-${language.code}`}
                  name="language"
                  type="radio"
                  checked={selectedLanguage === language.code}
                  onChange={() => changeLanguage(language.code)}
                  className="shrink-0 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500"
                />
              </div>
              <label htmlFor={`radio-${language.code}`} className="ms-3.5">
                <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-300">
                  {language.label}
                </span>
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;
