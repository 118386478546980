import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useUserStore from "../stores/userStore";
import { ActionIcon, CopyButton, Tooltip } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { IconCopy } from "@tabler/icons-react";

// Styled Components
const StartupButtonContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const StartupButtonStyled = styled.button`
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: var(--color-container-card-default);
  color: white;
  padding: 10px 15px;
  border-radius: 8px;
  width: 100%;
`;

const StartupInfoWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
`;

const Avatar = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 6px;
  margin-right: 10px;
`;

const StartupInfo = styled.div`
  flex: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StartupName = styled.h4`
  color: var(--color-background-card);
  font-weight: var(--font-weight-h4);
  line-height: 12px;
`;

const StartupSubtext = styled.p`
  font-size: var(--font-size-body-4);
`;

const DropdownIcon = styled.span`
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
`;

const DropdownLink = styled.a`
  display: flex;
  align-items: center;
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  width: 100%;

  &:hover {
    background-color: #f2f2f2;
  }
`;

const StartupButton = () => {
  const {
    user: {
      startups,
      domains,
      user: { full_name, avatar },
    },
  } = useUserStore();

  const [startupDropdownOpen, setStartupDropdownOpen] = useState(false);
  const [domainDropdownOpen, setDomainDropdownOpen] = useState(false);
  const [activeStartup, setActiveStartup] = useState(null);
  const [activeDomain, setActiveDomain] = useState(null);

  // Insert a main site domain entry
  const domainsWithMain = [{ name: "Main Site", subdomain: "" }, ...domains];

  useEffect(() => {
    const currentHostname = window.location.hostname;
    const currentSubdomain =
      currentHostname.split(".")[0] === "example"
        ? ""
        : currentHostname.split(".")[0];
    const matchedDomain = domainsWithMain.find(
      (domain) => domain.domain === currentSubdomain
    );

    console.log(domainsWithMain);

    if (matchedDomain) setActiveDomain(matchedDomain);
    else setActiveDomain(domainsWithMain[0]);

    const currentSlug = window.location.pathname.split("/").pop();
    const matchedStartup = startups.find(
      (startup) => startup.slug === currentSlug
    );

    if (matchedStartup) setActiveStartup(matchedStartup);
  }, []);

  const toggleStartupDropdown = () => {
    setStartupDropdownOpen(!startupDropdownOpen);
    setDomainDropdownOpen(false);
  };

  const toggleDomainDropdown = () => {
    setDomainDropdownOpen(!domainDropdownOpen);
    setStartupDropdownOpen(false);
  };

  const handleStartupSwitch = (startup) => {
    setActiveStartup(startup);
    setStartupDropdownOpen(false);
  };

  const handleDomainSwitch = (domain) => {
    if (domain === activeDomain) {
      setDomainDropdownOpen(false);
      return;
    }

    setActiveDomain(domain);
    setDomainDropdownOpen(false);

    const currentUrl = new URL(window.location.href);
    const domainParts = currentUrl.hostname.split(".");

    // Remove port if it exists
    const port = currentUrl.port ? `:${currentUrl.port}` : "";

    // Extract the base domain (last two parts of the hostname)
    // For example, from "sub.example.com", get "example.com"
    const baseDomain =
      domainParts.length > 2
        ? domainParts.slice(-2).join(".")
        : currentUrl.hostname;

    // Construct the new URL
    let newHostname;
    if (domain.domain) {
      newHostname = `${domain.domain}.${baseDomain}`;
    } else {
      newHostname = baseDomain;
    }

    // Reconstruct the full URL with the new hostname and port
    const newUrl = `${currentUrl.protocol}//${newHostname}${port}${currentUrl.pathname}${currentUrl.search}${currentUrl.hash}`;

    // console.log(newUrl)
    // return
    // Navigate to the new URL
    window.location.href = newUrl;
  };

  // if (!startups.length && domainsWithMain.length === 1) return null;

  return (
    <StartupButtonContainer>
      {/* Domain Selector */}
      <div className="relative inline-block w-64 mb-4 w-full">
        <div className="flex items-center gap-2">
          <button
            onClick={toggleDomainDropdown}
            className="flex items-center justify-between w-full p-3 bg-white rounded-lg shadow-sm hover:bg-gray-50"
          >
            <div className="flex items-center">
              <div className="w-3 h-3 rounded-full bg-green-500 mr-3"></div>
              {/* Display active domain or main site */}
              <span className="text-gray-900">{activeDomain?.name}</span>
            </div>
            <DropdownIcon>
              <svg
                className={`w-2 h-2 transform ${
                  domainDropdownOpen ? "rotate-180" : ""
                }`}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path d="M4 4.25L0.25 0.5H7.75L4 4.25Z" fill="#514D45" />
              </svg>
            </DropdownIcon>
          </button>
          {/* Copy invite link button */}
          {activeDomain?.signup_url && (
            <CopyButton value={activeDomain?.signup_url} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip
                  label={copied ? "Copied" : `Copy Invite Link to ${activeDomain?.name}`}
                  withArrow
                  position="right"
                  color="teal"
                  p={12}
                >
                  <ActionIcon
                    color={copied ? "teal" : "gray"}
                    variant="subtle"
                    onClick={copy}
                  >
                    {copied ? <IconCheck size={18} /> : <IconCopy size={18} />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          )}
        </div>

        {domainDropdownOpen && (
          <DropdownMenu>
            {domainsWithMain.map((domain, index) => (
              <DropdownLink
                key={index}
                onClick={() => handleDomainSwitch(domain)}
              >
                <div className="w-3 h-3 rounded-full bg-green-500 mr-3"></div>
                <span className="text-gray-900">{domain.name}</span>
              </DropdownLink>
            ))}
          </DropdownMenu>
        )}
      </div>

      {/* Startup Selector */}

      <StartupButtonStyled onClick={toggleStartupDropdown}>
        <Avatar
          src={avatar?.url || "/api/placeholder/45/45"}
          alt="User Avatar"
        />
        <StartupInfoWrapper>
          <StartupInfo>
            <StartupName>{full_name}</StartupName>
            <StartupSubtext>
              {activeStartup ? activeStartup.name : "Go to Project"}
            </StartupSubtext>
          </StartupInfo>
          <DropdownIcon>
            <svg
              className={`w-2 h-2 transform ${
                startupDropdownOpen ? "rotate-180" : ""
              }`}
              viewBox="0 0 8 5"
              fill="none"
            >
              <path d="M4 4.25L0.25 0.5H7.75L4 4.25Z" fill="#514D45" />
            </svg>
          </DropdownIcon>
        </StartupInfoWrapper>
      </StartupButtonStyled>

      {startupDropdownOpen && (
        <DropdownMenu>
          {startups.map((startup, index) => (
            <DropdownLink
              key={index}
              href={`/startup_entities/${startup.slug}`}
            >
              <Avatar
                src={
                  startup.image ||
                  "https://flowbite.com/docs/images/examples/image-3@2x.jpg"
                }
                alt="Startup Avatar"
              />
              <div>
                <StartupName>{startup.name}</StartupName>
                <StartupSubtext>{startup.user_role}</StartupSubtext>
              </div>
            </DropdownLink>
          ))}
        </DropdownMenu>
      )}
    </StartupButtonContainer>
  );
};

export default StartupButton;
