import React, { useState, useEffect } from "react";
import parse from "html-react-parser";

const Notification = ({ type, content, setNotification }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      if (setNotification) setNotification()
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (!visible) return null;

  return (
    <div
      className={`bg-${
        type === "failure" ? "red" : "green"
      }-100 border-l-4 border-${type === "failure" ? "red" : "green"}-500 text-${
        type === "failure" ? "red" : "green"
      }-700 p-2 mb-4`}
      role="alert"
    >
      <p className="font-bold text-sm">{type === "failure" ? "Error!" : "Success!"}</p>
      {parse(`<p className="mt-2 text-sm">${content}</p>`)}
    </div>
  );
};

export default Notification;
