import React, { useState } from "react";
import {
  Button,
  TextInput,
  PasswordInput,
  Group,
  MantineProvider,
  Divider,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import fetchApi from "../Lib/api";
import Notification from "../Lib/Notification";
import { GoogleButton } from "./GoogleButton";
import { LinkedInButton } from "./LinkedInButton";

export default function SignUpModal({ token, tenant_name, tenant_logo }) {
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
    validate: {
      first_name: (val) =>
        val.trim().length > 0 ? null : "First name is required",
      last_name: (val) =>
        val.trim().length > 0 ? null : "Last name is required",
      email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
      password: (val) =>
        val.length >= 6
          ? null
          : "Password should include at least 6 characters",
      password_confirmation: (val, values) =>
        val === values.password ? null : "Password confirmation does not match",
    },
  });

  const handleSocialLogin = async (provider) => {
    setNotification(null);
    setLoading(true);

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    try {
      // Redirect to backend social login endpoint
      const response = await fetchApi(`/users/auth/${provider}?token=${token}`, "GET");

      if (response.ok) {
        const data = await response.json();

        // Redirect to authorization URL or handle successful login
        if (data.redirect_url) {
          window.location.href = data.redirect_url;
        } else {
          setNotification({
            type: "success",
            content: `${provider} login initiated`,
          });
        }
      } else {
        const errorData = await response.json();
        setNotification({
          type: "failure",
          content: errorData.error || `${provider} login failed`,
        });
      }
    } catch (error) {
      console.error(`${provider} Login Error:`, error);
      setNotification({
        type: "failure",
        content: `Network error with ${provider} login`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    setNotification(null);
    setLoading(true);

    try {
      const response = await fetchApi(`/users?redirect_to=${encodeURIComponent('/ai_validation_tool')}`, "POST", {
        user: { ...values },
        token,
      });

      if (response.ok) {
        const data = await response.json();
        form.reset();
        setNotification({
          type: "success",
          content: "Signup successful. Please check your email to confirm.",
        });
        // window.location.href = data.redirect_to || "/dashboard/#/";
      } else {
        const errorData = await response.json();
        setNotification({
          type: "failure",
          content: errorData.error || "Signup failed",
        });
      }
    } catch (error) {
      console.error("Signup Error:", error);
      setNotification({
        type: "failure",
        content: "Network error. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <MantineProvider>
      <div className="text-center mb-6">
        {notification && <Notification {...notification} />}
        <div className="flex items-center gap-4 mx-auto w-fit">
          {tenant_logo && (
            <img
              src={tenant_logo}
              alt={`${tenant_name} Logo`}
              className="h-16 w-16 mx-auto object-contain"
            />
          )}
          <img
            src="/logo.png"
            alt="nowcollab Logo"
            className="h-24 w-24 mx-auto object-contain"
          />
        </div>
        <h3 className="text-lg font-bold text-gray-900 mt-4">
          Welcome to {tenant_name || "Nowcollab"}!
        </h3>
      </div>

      <form
        onSubmit={form.onSubmit(handleSubmit)}
        style={{ width: "260px", margin: "0 auto" }}
      >
        <TextInput
          label="First Name"
          required
          {...form.getInputProps("first_name")}
        />
        <TextInput
          label="Last Name"
          required
          mt="md"
          {...form.getInputProps("last_name")}
        />
        <TextInput
          label="Email"
          required
          mt="md"
          {...form.getInputProps("email")}
        />
        <PasswordInput
          label="Password"
          required
          mt="md"
          {...form.getInputProps("password")}
        />
        <PasswordInput
          label="Confirm Password"
          required
          mt="md"
          {...form.getInputProps("password_confirmation")}
        />

        <Button fullWidth mt="xl" type="submit" loading={loading}>
          Sign Up
        </Button>

        <Divider
          label="Or continue with"
          labelPosition="center"
          my="lg"
          variant="dotted"
        />

        <Group grow mb="md" mt="md">
          <GoogleButton radius="xl" onClick={() => handleSocialLogin("google")}>
            Google
          </GoogleButton>
          <LinkedInButton
            radius="xl"
            onClick={() => handleSocialLogin("linkedin")}
          >
            LinkedIn
          </LinkedInButton>
        </Group>
      </form>
    </MantineProvider>
  );
}
