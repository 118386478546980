import React, { useRef, useState } from "react";
import fetchApi from "../Lib/api";
import TagsInput from "../Lib/TagsInput";
import AddphotoIcon from "../../stories/assets/add-photo.svg";
import styled from "styled-components";

const UploadBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  border: 1px dashed var(--color-button-accent);
  background-color: var(--color-background-light);
  border-radius: 4px;
  cursor: pointer;
`;

const UploadIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 16px;
`;

const CombinedSignUpForm = () => {
  const [formType, setFormType] = useState("expert");
  const [notification, setNotification] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [file, setFile] = useState(null);

  const fileInputRef = useRef(null);

  // Expert form state
  const [expertFormData, setExpertFormData] = useState({
    full_name: "",
    email: "",
    phone: "",
    linkedin: "",
    terms_and_policy: false,
  });

  // Company form state
  const [companyFormData, setCompanyFormData] = useState({
    company_name: "",
    company_website: "",
    phone: "",
    contact_person: "",
    email: "",
    industry_sector: [],
    desired_expertise: "",
    project_description: "",
    terms_and_policy: false,
  });
  const [selectedSectors, setSelectedSectors] = useState([]);

  const handleExpertChange = (e) => {
    const { name, value, type, checked } = e.target;
    setExpertFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile); // Save the file to state
      console.log("Selected file:", selectedFile);
    }
  };

  const handleCompanyChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCompanyFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleCompanySectorsChange = (selected) => {
    setSelectedSectors(selected);
    setCompanyFormData((prevData) => ({
      ...prevData,
      industry_sector: selected,
    }));
  };

  const handleExpertSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setNotification(
        <div role="alert" className="text-red-700">
          <p>Please select a file before submitting.</p>
        </div>
      );
      return;
    }

    const formData = new FormData();
    formData.append("expert_signup[file]", file); // Append the file to FormData

    Object.entries(expertFormData).forEach(([key, value]) => {
      formData.append(`expert_signup[${key}]`, value);
    });

    try {
      const response = await fetchApi(
        "/expert_signups",
        "POST",
        formData,
        true
      );

      const data = await response.json();

      if (response.ok) {
        setNotification(
          <div role="alert">
            <p className="text-green-700 font-semibold mb-4">{data.message}</p>
            <div className="p-6">
              <p className="mb-4">
                Thank you for joining NowCollab! We appreciate your interest and
                will review your information shortly. Our team will reach out to
                you within 3 business days with the next steps.
              </p>
              <p className="mb-6">
                While you wait, feel free to connect with us on social media or
                check out some useful links.
              </p>
              <div className="flex justify-between">
                <ul className="flex space-x-4">
                  <li>
                    <a href="#" className="text-blue-600 hover:text-blue-800">
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-blue-600 hover:text-blue-800">
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-blue-600 hover:text-blue-800">
                      LinkedIn
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-blue-600 hover:text-blue-800">
                      Instagram
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        );
        resetExpertForm();
        setIsSubmitted(true);
      } else {
        setNotification(
          <div role="alert" className="text-red-700">
            <p>{data.message || "There was an error. Please try again."}</p>
          </div>
        );
      }
    } catch (error) {
      console.error("Submission error:", error);
      setNotification(
        <div role="alert" className="text-red-700">
          <p>An error occurred. Please try again later.</p>
        </div>
      );
    }
  };

  const handleCompanySubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetchApi("/corporate_signups", "POST", {
        corporate_signup: {
          ...companyFormData,
          industry_sector: companyFormData.industry_sector.join(", "),
        },
      });

      const data = await response.json();

      if (response.ok) {
        setNotification(
          <div role="alert">
            <p className="text-green-700 font-semibold mb-4">{data.message}</p>
            <div className="p-6">
              <p className="mb-4">
                Thank you for joining NowCollab! We appreciate your interest and
                will review your information shortly. Our team will reach out to
                you within 3 business days with the next steps.
              </p>
              <p className="mb-6">
                While you wait, feel free to connect with us on social media or
                check out some useful links.
              </p>
              <div className="flex justify-between">
                <ul className="flex space-x-4">
                  <li>
                    <a href="#" className="text-blue-600 hover:text-blue-800">
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-blue-600 hover:text-blue-800">
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-blue-600 hover:text-blue-800">
                      LinkedIn
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-blue-600 hover:text-blue-800">
                      Instagram
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        );
        resetCompanyForm();
        setIsSubmitted(true);
      } else {
        setNotification(
          <div role="alert" className="text-red-700">
            <p>{data.message || "There was an error. Please try again."}</p>
          </div>
        );
      }
    } catch (error) {
      console.error("Submission error:", error);
      setNotification(
        <div role="alert" className="text-red-700">
          <p>An error occurred. Please try again later.</p>
        </div>
      );
    }
  };

  const resetExpertForm = () => {
    setExpertFormData({
      full_name: "",
      email: "",
      phone: "",
      position: "",
      linkedin: "",
      phd: "No",
      institution: "",
      expertise: [],
      bio: "",
      terms_and_policy: false,
    });
    setFile(null)
  };

  const resetCompanyForm = () => {
    setCompanyFormData({
      company_name: "",
      company_website: "",
      phone: "",
      contact_person: "",
      email: "",
      industry_sector: [],
      desired_expertise: "",
      project_description: "",
      terms_and_policy: false,
    });
    setSelectedSectors([]);
  };

  const handleSetForm = (e) => {
    setNotification("");
    setFormType(e);
  };

  return (
    <div className="w-full">
      <div
        className="hero-content border border-gray-200 rounded-lg shadow-md w-30"
        id="expert-section"
      >
        {formType === "expert" ? (
          <div className="bg-blue-500 h-4 rounded-t-lg"></div>
        ) : (
          <div class="bg-green-500 h-4 rounded-t-lg"></div>
        )}

        <div className="p-6">
          {formType === "expert" ? (
            <p className="hero-subtitle">For Experts and Professors</p>
          ) : (
            <p class="hero-subtitle corporate">For Businesses</p>
          )}
          <div className="flex">
            <button
              onClick={() => handleSetForm("expert")}
              className={`btn ${
                formType !== "expert" ? "btn-primary" : "bg-gray-200"
              }`}
            >
              FOR EXPERT
            </button>
            <button
              onClick={() => handleSetForm("company")}
              className={`btn ${
                formType !== "company" ? "bg-green-500" : "bg-gray-200"
              }`}
            >
              FOR BUSINESSES
            </button>
          </div>

          {notification ? (
            <div className="mt-6">{notification}</div>
          ) : (
            <>
              {" "}
              {formType === "expert" ? (
                <form onSubmit={handleExpertSubmit} className="space-y-2">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {/* Full Name */}
                    <div>
                      <label className="inline font-light mb-1 leading-[1.4]">
                        Full Name<span className="text-red-500 inline">*</span>
                      </label>
                      <input
                        type="text"
                        name="full_name"
                        value={expertFormData.full_name}
                        onChange={handleExpertChange}
                        required
                        autoFocus
                        className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-12 leading-[1.5] px-3 focus:outline-none w-full"
                      />
                    </div>
                    {/* Email */}
                    <div>
                      <label className="inline font-light mb-1 leading-[1.4]">
                        Email Address
                        <span className="text-red-500 inline">*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={expertFormData.email}
                        onChange={handleExpertChange}
                        required
                        className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-12 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {/* LinkedIn */}
                    <div>
                      <label className="inline font-light mb-1 leading-[1.4]">
                        LinkedIn Profile
                        <span className="text-red-500 inline">*</span>
                      </label>
                      <input
                        type="url"
                        name="linkedin"
                        value={expertFormData.linkedin}
                        onChange={handleExpertChange}
                        required
                        className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-12 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                      />
                    </div>

                    {/* Phone */}
                    <div>
                      <label className="inline font-light mb-1 leading-[1.4]">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        name="phone"
                        value={expertFormData.phone}
                        onChange={handleExpertChange}
                        className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-12 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="inline font-light mt-3 mb-1 leading-[1.4]">
                      Professional Resume
                      <span className="text-red-500 inline">*</span>
                    </label>
                    {!file ? (
                      <UploadBox onClick={() => fileInputRef.current.click()}>
                        <UploadIcon src={AddphotoIcon} alt="upload" />
                        <p style={{ textAlign: "center", fontSize: "1.2rem" }}>
                          <strong>Click to upload</strong> or Drag and Drop
                          resume
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                          Maximum file size 2MB
                        </p>
                      </UploadBox>
                    ) : (
                      <div className="p-4 border rounded-lg bg-gray-50 flex items-center justify-between">
                        <div className="flex items-center gap-3">
                          <div className="p-2 bg-white rounded-lg">
                            <svg
                              className="w-8 h-8 text-gray-500"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                              />
                            </svg>
                          </div>

                            <p className="font-medium text-gray-900">
                              {file.name}
                            </p>

                        </div>
                      </div>
                    )}
                  </div>

                  {/* Terms */}
                  <div className="flex items-start">
                    <input
                      type="checkbox"
                      name="terms_and_policy"
                      checked={expertFormData.terms_and_policy}
                      onChange={handleExpertChange}
                      required
                      className="h-8 w-8 text-blue-600 border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
                    />
                    <label className="ml-2 block text-gray-900 text-[12px]">
                      I agree to the{" "}
                      <a
                        href="/terms_and_policy#terms"
                        className="text-blue-600 hover:text-blue-500 inline ml-1"
                      >
                        Terms of Service{" "}
                      </a>{" "}
                      and{" "}
                      <a
                        href="/terms_and_policy#privacy_policy"
                        className="text-blue-600 hover:text-blue-500 inline ml-1"
                      >
                        Privacy Policy
                      </a>
                      .
                    </label>
                  </div>

                  <button
                    type="submit"
                    className="w-full  px-4 inline-flex justify-center items-center gap-x-2 font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50"
                  >
                    Submit as Expert
                  </button>
                </form>
              ) : (
                <form onSubmit={handleCompanySubmit} className="space-y-2">
                  {/* Company Name */}
                  <div>
                    <label className="inline font-light text-md mb-1 leading-[1.4]">
                      Company Name<span className="text-red-500 inline">*</span>
                    </label>
                    <input
                      type="text"
                      name="company_name"
                      value={companyFormData.company_name}
                      onChange={handleCompanyChange}
                      required
                      autoFocus
                      className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-12 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                    />
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {/* Company Website */}
                    <div>
                      <label className="inline font-light text-md mb-1 leading-[1.4]">
                        Company Website
                      </label>
                      <input
                        type="url"
                        name="company_website"
                        value={companyFormData.company_website}
                        onChange={handleCompanyChange}
                        className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-12 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                      />
                    </div>

                    {/* Phone Number */}
                    <div>
                      <label className="inline font-light text-md mb-1 leading-[1.4]">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        name="phone"
                        value={companyFormData.phone}
                        onChange={handleCompanyChange}
                        className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-12 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                      />
                    </div>
                  </div>

                  {/* Contact Person */}
                  <div>
                    <label className="inline font-light text-md mb-1 leading-[1.4]">
                      Contact Person's Full Name
                      <span className="text-red-500 inline">*</span>
                    </label>
                    <input
                      type="text"
                      name="contact_person"
                      value={companyFormData.contact_person}
                      onChange={handleCompanyChange}
                      required
                      className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-12 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                    />
                    <p className="mt-1 text-[11px] text-gray-500">
                      Full name of the person applying (e.g., Sam John).
                    </p>
                  </div>

                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {/* Email */}
                    <div>
                      <label className="inline font-light text-md mb-1 leading-[1.4]">
                        Email Address
                        <span className="text-red-500 inline">*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={companyFormData.email}
                        onChange={handleCompanyChange}
                        required
                        className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-12 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                      />
                      <p className="mt-1 text-[11px] text-gray-500">
                        Email associated with your corporation.
                      </p>
                    </div>

                    {/* Industry Sector */}
                    <div>
                      <label className="inline font-light text-md mb-1 leading-[1.4]">
                        Industry Sector
                        <span className="text-red-500 inline">*</span>
                      </label>
                      <TagsInput
                        data={[]}
                        value={selectedSectors}
                        onChange={handleCompanySectorsChange}
                      />
                      <p className="mt-1 text-[11px] text-gray-500">
                        Type, press Enter to save and to add more sectors
                      </p>
                    </div>
                  </div>

                  {/* Project Description */}
                  <div>
                    <label className="inline font-light text-md mb-1 leading-[1.4]">
                      Brief Project Description
                      <span className="text-red-500 inline">*</span>
                    </label>
                    <textarea
                      name="project_description"
                      value={companyFormData.project_description}
                      onChange={handleCompanyChange}
                      required
                      className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-20 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                    />
                    <p className="mt-1 text-[11px] text-gray-500">
                      Briefly describe your project or area of collaboration.
                    </p>
                  </div>

                  {/* Terms */}
                  <div className="flex items-start">
                    <input
                      type="checkbox"
                      name="terms_and_policy"
                      checked={companyFormData.terms_and_policy}
                      onChange={handleCompanyChange}
                      required
                      className="h-8 w-8 text-blue-600 border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
                    />
                    <label className="ml-2 block text-gray-900 text-[12px]">
                      I agree to the{" "}
                      <a
                        href="/terms_and_policy#terms"
                        className="text-blue-600 hover:text-blue-500 inline ml-1"
                      >
                        Terms of Service{" "}
                      </a>{" "}
                      and{" "}
                      <a
                        href="/terms_and_policy#privacy_policy"
                        className="text-blue-600 hover:text-blue-500 inline ml-1"
                      >
                        Privacy Policy
                      </a>
                      .
                    </label>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="w-full bg-blue-600 hover:bg-blue-800 text-white font-bold rounded-lg"
                    >
                      Submit as Company
                    </button>
                  </div>
                </form>
              )}
            </>
          )}
        </div>
      </div>
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default CombinedSignUpForm;
