import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Styled Components
const ProgressContainer = styled.div`
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 8px;
  overflow: hidden;
  height: 20px;
  margin-top: 20px;
  position: relative;
`;

const ProgressBar = styled.div`
  height: 100%;
  background-color: #3498db;
  transition: width 0.1s linear;
`;

const ProgressText = styled.p`
  text-align: center;
  font-family: Arial, sans-serif;
  color: #666;
  margin-top: 10px;
`;

const RemainingTime = styled.p`
  text-align: center;
  font-family: Arial, sans-serif;
  color: #666;
  font-size: 0.9rem;
  margin-top: 5px;
`;

const DoneText = styled.p`
  text-align: center;
  font-family: Arial, sans-serif;
  color: #28a745;
  margin-top: 10px;
`;

const TimeBasedProgressBar = ({ duration = 70000 }) => {
  const [progress, setProgress] = useState(0);
  const [remainingTime, setRemainingTime] = useState(duration);

  useEffect(() => {
    const interval = 100; // Update every 100ms
    const totalDuration = duration;

    const timer = setInterval(() => {
      setRemainingTime((prev) => Math.max(prev - interval, 0));

      const newProgress = Math.min(((totalDuration - remainingTime) / totalDuration) * 100, 100);
      setProgress(newProgress);

      if (remainingTime <= 0) {
        clearInterval(timer);
      }
    }, interval);

    return () => clearInterval(timer); // Cleanup on component unmount
  }, [remainingTime, duration]);

  const percentage = Math.floor(progress);
  const remainingSeconds = Math.ceil(remainingTime / 1000);

  return (
    <div>
      <ProgressContainer>
        <ProgressBar style={{ width: `${percentage}%` }} />
      </ProgressContainer>
      <ProgressText>Loading... {percentage}%</ProgressText>
      {remainingTime > 0 && (
        <RemainingTime>Please hold on, we are preparing the roadmap timeline...</RemainingTime>
      )}
      {remainingTime <= 0 && <DoneText>Done! 🎉</DoneText>}
    </div>
  );
};

export default TimeBasedProgressBar;
