// stores/startupStore.js
import { create } from "zustand";
import fetchApi from "../components/Lib/api";

function safeParse(value) {
  try {
    // Check if the value is a non-null string before parsing
    return typeof value === "string" && value !== null
      ? JSON.parse(value)
      : { blocks: [] };
  } catch {
    return { blocks: [] }; // Fallback in case of JSON parsing errors
  }
}

const useStartupStore = create((set, get) => ({
  id: null,
  startup: null, // Holds the startup_entity
  program: null, // Holds the program related to the startup
  isOwner: false, // Whether the user is the owner of the startup
  isMember: false, // Whether the user is a member of the startup
  role: null, // The role of the user in the startup
  isFollowing: false, // Whether the user is following the startup
  existingJoinRequest: false, // Whether there is an existing join request
  editMode: false,
  members: [],
  openings: [],
  slug: "",
  entity: "Startup",
  userInitials: "AM",
  content: {}, // { [pageId]: { [index]: content } }
  privacy: {}, // { [pageId]: { [index]: privacy } }
  files: {}, // { [pageId]: { [index]: files } }
  selectedProgram: {
    switch: false,
    cid: 0, // pageId
    sid: 0, // index
  },
  openChat: false,
  showFunding: false,
  startActivity: false,
  startTime: Date.now(),
  pendingJoinRequest: 0,
  setEditorState: () => {
    console.log("Initializing editor state");
    set((state) => {
      const pageId = state.selectedProgram.cid; // Use cid as pageId
      const category_id = state.program?.categories[pageId]?.id;
      const subCategories = state.program?.categories[pageId]?.sub_categories;
      console.log(subCategories)

      const pageContent = {};
      const pagePrivacy = {};
      const pageFiles = {};

      subCategories.forEach((subCategory, index) => {
        pageContent[index] = {
          response: safeParse(subCategory.response),
          touched: false,
          id: subCategory.id,
          category_id,
          name: subCategory.name,
          guide: safeParse(subCategory.guide),
          wait: subCategory.waiting_for_approval,
          chats: subCategory.limited_conversation_histories,
        };
        pagePrivacy[index] = subCategory.privacy;
        pageFiles[index] = []; // Initialize files array for this tab
      });

      return {
        content: {
          ...state.content,
          [pageId]: pageContent,
        },
        privacy: {
          ...state.privacy,
          [pageId]: pagePrivacy,
        },
        files: {
          ...state.files,
          [pageId]: pageFiles,
        },
      };
    });
  },

  setContent: (pageId, index, response, guide) => {
    set((state) => {
      const updatedPageContent = {
        ...(state.content[pageId] || {}),
        [index]: {
          ...(state.content[pageId]?.[index] || {}),
          response: response || state.content[pageId]?.[index]?.response,
          guide: guide || state.content[pageId]?.[index]?.guide,
          touched: true,
          name: state.content[pageId]?.[index]?.name,
        },
      };

      console.log(updatedPageContent);

      return {
        content: {
          ...state.content,
          [pageId]: updatedPageContent,
        },
      };
    });
  },

  setPrivacy: (pageId, index, c) => {
    set((state) => {
      const pagePrivacy = { ...(state.privacy[pageId] || {}) };
      pagePrivacy[index] = c;
      console.log(pagePrivacy);
      return {
        privacy: {
          ...state.privacy,
          [pageId]: pagePrivacy,
        },
      };
    });
  },

  setFiles: (pageId, index, newFiles) => {
    set((state) => {
      const pageFiles = { ...(state.files[pageId] || {}) };
      pageFiles[index] = newFiles; // Overwrite files for the given index

      return {
        files: {
          ...state.files,
          [pageId]: pageFiles,
        },
      };
    });
  },
  setEditMode: (isOpened) => set({ editMode: isOpened }),
  setIsFollowing: (follow) => set({ isFollowing: follow }),

  // Setters for startup-related fields
  setStartup: (data) => {
    console.log
    set({
      startup: data.startup_entity,
      id: data.startup_entity.id,
      program: data.startup_entity.program
        ? {
            ...data.startup_entity.program,
            categories: data.startup_entity.program?.categories
              .map((category) => {
                // Filter sub_categories based on criteria
                const filteredSubCategories = category.sub_categories.filter(
                  (subCategory) =>
                    subCategory.is_active &&
                    (subCategory.privacy === "Everyone" ||
                      (subCategory.privacy === "Only me" && data.is_owner) ||
                      (subCategory.privacy === "Members" && data.is_member))
                );

                // Return the updated category with filtered sub_categories only if there's at least one sub_category
                return filteredSubCategories.length > 0
                  ? {
                      ...category,
                      sub_categories: filteredSubCategories,
                    }
                  : null;
              })
              .filter((category) => category !== null),
          }
        : null,
      isOwner: data.is_owner,
      slug: data.startup_entity.slug,
      isMember: data.is_member,
      role: data.role,
      isFollowing: data.is_following,
      existingJoinRequest: data.existing_join_request,
      openings: data.startup_entity.openings,
      members: data.startup_entity.members,
      entity: data.startup_entity.entity_type,
      pendingJoinRequest: data.pending_join_requests_count,
    });

    if (data.startup_entity.program) {
      const setEditorState = get().setEditorState;
      setEditorState();
    }
  },

  setStartupImage: (image) =>
    set((state) => ({
      startup: { ...state.startup, image },
    })),

  setSelectedProgram: (s) =>
    set((state) => ({
      selectedProgram: { ...state.selectedProgram, ...s },
    })),

  setProgram: (s) =>
    set((state) => ({
      program: { ...state.program, ...s },
    })),

  setCategory: (cat) => {
    set((state) => ({
      program: {
        ...state.program,
        categories: state.program?.categories?.map((c) =>
          c.id == cat.id ? cat : c
        ),
      },
    }));
  },

  setSubcategory: (sub) => {
    set((state) => ({
      program: {
        ...state.program,
        categories: state.program?.categories?.map((c, i) =>
          i == state.selectedProgram.cid
            ? {
                ...c,
                sub_categories: c.sub_categories.map((s, i) =>
                  i == state.selectedProgram.sid ? sub : s
                ),
              }
            : c
        ),
      },
    }));
  },

  setOpenings: (s) =>
    set((state) => ({
      openings: [...state.openings, s],
    })),

  setMembers: (s) =>
    set((state) => ({
      members: [...state.members, s],
    })),

  removeMembers: (id) =>
    set((state) => ({
      members: state.members.filter((p) => p.id != id),
      isMember: false,
    })),

  removeOpenings: (id) =>
    set((state) => ({
      openings: state.openings.filter((p) => p.id != id),
    })),

  toggleOpenChat: () => set((state) => ({ openChat: !state.openChat })),
  toggleShowFunding: () =>
    set((state) => ({ showFunding: !state.showFunding })),

  logTime: async (slug) => {
    const { startTime } = get();
    const endTime = Date.now();
    const timeSpentInMinutes = (endTime - startTime) / 1000 / 60;

    try {
      const response = await fetchApi(
        `/startup_entities/${slug}/log_time`,
        "POST",
        {
          time_spent: timeSpentInMinutes,
        }
      );

      if (response.ok) {
        const res = await response.json();
        console.log("Time logged successfully:", res);
      } else {
        const msg = (await response.json()).error;
        console.error("Failed to log time:", msg);
      }
    } catch (error) {
      console.error("Error logging time:", error);
    }
  },

  handleFileChange: async (event, slug) => {
    const selectedFile = event.target.files[0];
    const imageURL = URL.createObjectURL(selectedFile);
    set({ startupImage: imageURL });

    try {
      const formData = new FormData();
      formData.append("startup_entity[image]", selectedFile);
      const response = await fetchApi(
        `/startup_entities/${slug}`,
        "PATCH",
        formData,
        true
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log("File updated successfully:", responseData);
      } else {
        console.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading file:", error.message);
    }
  },
}));

export default useStartupStore;
