import {
  Anchor,
  Button,
  Checkbox,
  Divider,
  Group,
  Select,
  Stack,
  Switch,
  Text,
  TextInput,
  Title,
  UnstyledButton,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import fetchApi from "../Lib/api";
import { countriesData, industriesData } from "../Onboarding/data";
import { IconArrowLeft } from "@tabler/icons-react";
import Notification from "../Lib/Notification";
import { cleanText } from "../Lib/profanityCleaner";

const getSubdomain = () => {
  const hostname = window.location.hostname; // e.g., 'subdomain.example.com'
  const parts = hostname.split(".");

  // Assuming 'example.com' is your main domain
  if (parts.length > 2) {
    return parts[0]; // The subdomain is the first part
  }
  return null; // No subdomain found
};

export default function CreateStartup({ project }) {
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [isProject, setIsProject] = useState(false);
  const form = useForm({
    initialValues: {
      name: "",
      description: "",
      industry: "",
      stage: "",
      project_type: "",
      country: "",
      city: "",
      website: "",
      linkedin: "",
      social_media: "",
      public_listed: true,
    },
    validate: {
      name: (value) => {
        if (!value) {
          return "Name is required";
        }
        return null;
      },
      description: (value) => {
        if (!value) {
          return "Description is required";
        }
        return null;
      },
      industry: (value) => {
        if (!value) {
          return "Industry is required";
        }
        return null;
      },
      country: (value) => {
        if (!value) {
          return "Country is required";
        }
        return null;
      },
      city: (value) => {
        if (!value) {
          return "City is required";
        }
        return null;
      },
    },
  });

  const subdomain = getSubdomain();

  useEffect(() => {
    if (project) return;

    // Get the current path
    const path = window.location.pathname;

    // Split the path and extract the last part (the token)
    const parts = path.split("/");
    const capturedToken = parts.pop() || parts.pop(); // Handle possible trailing slash

    setToken(capturedToken);
  }, []);

  const handleSubmit = async (e) => {
    const url = isProject ? "/project_entities" : "/startup_entities";
    const key = isProject ? "project_entity" : "startup_entity";
    setLoading(true);

    try {
      const response = await fetchApi(url, "POST", {
        [key]: {
          ...form.values,

          description: cleanText(form.values.description),
        },
        token,
      });

      if (response.ok) {
        const res = (await response.json()).startup_entity;
        // setNotification({ type: "success", content: msg });
        console.log(res);
        window.location.href = `${url}/${res.slug}`;
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <main className="flex justify-center">
        <div className="max-w-[900px] w-full px-6 py-12">
          {/* Back Navigation */}
          <Anchor onClick={() => window.history.back()} target="_blank">
            <Group className="mb-2" spacing={4}>
              <IconArrowLeft size={18} color="#1c7ed6" />
              Go back
            </Group>
          </Anchor>

          {/* Notification */}
          {notification && <Notification {...notification} />}

          {/* Selection Buttons */}
          <div className="flex space-x-4 m-4">
            {/* Startup Option */}
            <div
              className={`relative p-4 transition-colors duration-150 ease-in-out border rounded-md cursor-pointer 
            ${!isProject ? "border-blue-500 bg-blue-50" : "border-gray-300"} 
            hover:bg-gray-100`}
              onClick={() => setIsProject(false)}
            >
              <div className="flex items-start space-x-4">
                <div className="w-5 h-5 border-2 rounded-sm flex items-center justify-center">
                  {!isProject && (
                    <div className="w-3 h-3 bg-blue-500 rounded-sm" />
                  )}
                </div>
                <div>
                  <p className="font-mono font-bold text-base leading-tight text-gray-900">
                    Create a Startup
                  </p>
                  <p className="mt-2 text-sm text-gray-500">
                    Plan and launch a startup with tailored programs and
                    resources to help you succeed.
                  </p>
                </div>
              </div>
            </div>

            {/* Project Option */}
            <div
              className={`relative p-4 transition-colors duration-150 ease-in-out border rounded-md cursor-pointer 
            ${isProject ? "border-blue-500 bg-blue-50" : "border-gray-300"} 
            hover:bg-gray-100`}
              onClick={() => setIsProject(true)}
            >
              <div className="flex items-start space-x-4">
                <div className="w-5 h-5 border-2 rounded-sm flex items-center justify-center">
                  {isProject && (
                    <div className="w-3 h-3 bg-blue-500 rounded-sm" />
                  )}
                </div>
                <div>
                  <p className="font-mono font-bold text-base leading-tight text-gray-900">
                    Create a Project
                  </p>
                  <p className="mt-2 text-sm text-gray-500">
                    Manage a project with specific goals, milestones, and
                    detailed timelines.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Form */}
          <form onSubmit={form.onSubmit(handleSubmit)} className="mt-4 px-2 ">
            <Stack spacing={4}>
              {/* Intro Text */}
              <Text className="text-base text-gray-600">
                Start your {isProject ? "project" : "startup"} journey. Provide
                the necessary details to proceed.
              </Text>

              <Divider
                my="xs"
                label={`${isProject ? "Project" : "Startup"} Information`}
                labelPosition="center"
                className="lg:w-2/3"
              />

              {/* Name Input */}
              <TextInput
                required
                label={`${isProject ? "Project" : "Startup"} Name`}
                value={form.values.name}
                onChange={(event) =>
                  form.setFieldValue("name", event.currentTarget.value)
                }
                error={form.errors.name}
                radius="md"
                size="md"
                className="lg:w-2/3"
              />

              {/* Description Input */}
              <TextInput
                required
                label="Description"
                value={form.values.description}
                onChange={(event) =>
                  form.setFieldValue("description", event.currentTarget.value)
                }
                error={form.errors.description}
                disabled={!form.values.name}
                radius="md"
                size="md"
                className="lg:w-2/3"
              />

              {/* Industry Selector */}
              <Select
                label="Industry"
                radius="md"
                size="md"
                name="industry"
                data={industriesData}
                defaultValue={form.values.industry}
                onChange={(event) => form.setFieldValue("industry", event)}
                error={form.errors.industry}
                withAsterisk
                className="lg:w-2/3"
                disabled={!form.values.description}
                searchable
              />

              {/* Conditional Fields */}
              {isProject ? (
                <Select
                  label="Project Type"
                  radius="md"
                  key={isProject + ""}
                  size="md"
                  name="project_type"
                  data={["Freelance", "Institution"]}
                  defaultValue={form.values.project_type}
                  onChange={(event) =>
                    form.setFieldValue("project_type", event)
                  }
                  error={form.errors.project_type}
                  withAsterisk
                  className="lg:w-2/3"
                  disabled={!form.values.industry}
                />
              ) : (
                <Select
                  label="Development Stage"
                  radius="md"
                  size="md"
                  name="stage"
                  key={isProject + ""}
                  data={[
                    "Idea",
                    "Prototype",
                    "Start-up",
                    "Project",
                    "Growth",
                    "Maturity",
                  ]}
                  defaultValue={form.values.stage}
                  onChange={(event) => form.setFieldValue("stage", event)}
                  error={form.errors.stage}
                  withAsterisk
                  className="lg:w-2/3"
                  disabled={!form.values.project_type && !form.values.industry}
                />
              )}

              {/* Remaining Fields */}
              <Divider
                my="xs"
                label="Location Information"
                labelPosition="center"
                className="lg:w-2/3"
              />
              <Group
                className="flex-col md:flex-row flex-wrap lg:flex-nowrap lg:w-2/3"
                position="apart"
              >
                <Select
                  required
                  data={countriesData}
                  label="Country"
                  value={form.values.country}
                  onChange={(event) => form.setFieldValue("country", event)}
                  error={form.errors.country}
                  radius="md"
                  searchable
                  nothingFound="No options"
                  className="w-full"
                  disabled={!form.values.stage && !form.values.project_type}
                />
                <TextInput
                  required
                  label="City"
                  disabled={!form.values.country}
                  value={form.values.city}
                  onChange={(event) =>
                    form.setFieldValue("city", event.currentTarget.value)
                  }
                  error={form.errors.city}
                  radius="md"
                  size="md"
                  className="w-full"
                />
              </Group>

              <Divider
                my="xs"
                label="Links"
                labelPosition="center"
                className="lg:w-2/3"
              />
              <TextInput
                label="Website"
                value={form.values.website}
                onChange={(event) =>
                  form.setFieldValue("website", event.currentTarget.value)
                }
                error={form.errors.website}
                radius="md"
                size="md"
                className="lg:w-2/3"
                disabled={!form.values.city}
              />

              <Group
                className="flex-col md:flex-row flex-wrap lg:mt-2 lg:flex-nowrap lg:w-2/3"
                position="apart"
              >
                <TextInput
                  label="LinkedIn Link"
                  value={form.values.linkedin}
                  description="Don't have one for the company? Add your Own."
                  onChange={(event) =>
                    form.setFieldValue("linkedin", event.currentTarget.value)
                  }
                  error={form.errors.linkedin}
                  radius="md"
                  size="md"
                  className="w-full"
                  disabled={!form.values.city}
                />
                <TextInput
                  label="Social Media Link"
                  value={form.values.social_media}
                  description="Don't have one for the company? Add your Own."
                  onChange={(event) =>
                    form.setFieldValue(
                      "social_media",
                      event.currentTarget.value
                    )
                  }
                  error={form.errors.social_media}
                  radius="md"
                  size="md"
                  className="w-full"
                  disabled={!form.values.city}
                />
              </Group>

              <Divider
                my="xs"
                label={`Who can view this ${
                  isProject ? "Project" : "Startup"
                }?`}
                labelPosition="center"
                className="lg:w-2/3"
              />
              <Switch
                label={`${
                  form.values.public_listed
                    ? "Make it available to everyone"
                    : "Only members can view and have access to it."
                }`}
                onLabel="PUBLIC"
                offLabel="PRIVATE"
                size="lg"
                disabled={!form.values.city}
                checked={form.values.public_listed}
                onChange={(event) =>
                  form.setFieldValue(
                    "public_listed",
                    event.currentTarget.checked
                  )
                }
              />

              <Group className="lg:w-2/3 mt-4" position="right">
                <Button
                  type="submit"
                  variant="default"
                  loading={loading}
                  fullWidth
                  className="w-fit px-12"
                  disabled={!form.values.city}
                >
                  Create {isProject ? "Project" : "Startup"}
                </Button>
              </Group>
            </Stack>
          </form>
        </div>
      </main>
    </>
  );
}
