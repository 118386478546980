import React, { useCallback, useState } from "react";
import {
  Avatar,
  Button,
  Collapse,
  Modal,
  Stack,
  Text,
  Textarea,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import fetchApi from "../Lib/api";
import Notification from "../Lib/Notification";
import moment from "moment";
import FeatureButton from "../../stories/FeatureButton";
import { useTranslation } from "react-i18next";

export default function ContactMessage({ slug, isMember, role }) {
  const [opened, { open, close }] = useDisclosure(false);
  const [notification, setNotification] = useState(null);
  const [message_content, setMessageContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [oldMessages, setOldMessages] = useState([]);
  const [selectedChat, setSelectedChat] = useState(-1);
  const [reply, setReply] = useState("");
  const [replies, setReplies] = useState([]);
  const [userMessage, setUserMessage] = useState(null);
  const { t } = useTranslation();

  const MessageItem = useCallback(
    ({
      id,
      avatar_url,
      first_name,
      last_name,
      created_at,
      message_content,
      handleChaSelect,
    }) => (
      <div className="flex items-start gap-2.5 overflow-x-hidden">
        <Avatar
          size={45}
          src={avatar_url || "https://placehold.co/600x400?text=No Image"}
          radius={40}
        />
        <div className="flex flex-col gap-2 w-full leading-1.5 px-4 border-gray-200 rounded-e-xl rounded-es-xl">
          <div className="flex items-center space-x-2 rtl:space-x-reverse">
            <span className="text-sm font-semibold text-gray-700 ">
              {first_name} {last_name}
            </span>
            <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
              {moment(created_at).format("MMM Do YYYY")}
            </span>
          </div>
          <p
            className={`text-sm font-normal text-gray-600 ${
              selectedChat === id || !handleChaSelect ? "" : "truncate"
            } w-5/6`}
          >
            {message_content}
          </p>
          {handleChaSelect && (
            <p className="mt-1">
              <button
                onClick={() => handleChaSelect && handleChaSelect(id)}
                type="button"
                className="hs-collapse-toggle inline-flex items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              >
                <span>{selectedChat === id ? "Hide" : "Show"} chats</span>
                <svg
                  className={`${
                    selectedChat === id ? "rotate-180" : ""
                  } flex-shrink-0 w-4 h-4`}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="m6 9 6 6 6-6" />
                </svg>
              </button>
            </p>
          )}
        </div>
      </div>
    ),
    [selectedChat]
  );

  const handleChaSelect = (id) => {
    setReply("");
    if (selectedChat == id) setSelectedChat(-1);
    else {
      setSelectedChat(id);
      fetchReplies(id);
    }
  };

  const handleSend = async () => {
    try {
      const response = await fetchApi(
        `/startup_entities/${slug}/contact_messages`,
        "POST",
        {
          contact_message: { message_content },
        }
      );

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        setNotification({ type: "success", content: res.message });
        if (userMessage) {
          setUserMessage((p) => ({
            ...p,
            contact_messages_replies: [
              ...p.contact_messages_replies,
              { ...res.contact_message, user: res.user },
            ],
          }));
        } else {
          setUserMessage({ ...res.contact_message, user: res.user });
        }
        setMessageContent("");
        console.log("Request sent successful!");
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error("Request failed.");
      }
    } catch (error) {
      console.error("Error during Sending request", error);
    }
  };

  const handleReply = async () => {
    try {
      const response = await fetchApi(
        `/startup_entities/${slug}/contact_messages/${selectedChat}/contact_messages_replies`,
        "POST",
        {
          contact_messages_reply: { message_content: reply },
        }
      );

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        setNotification({ type: "success", content: res.message });
        setReplies((p) => [...p, { ...res.reply, user: res.user }]);
        setReply("");
        console.log("Request sent successful!");
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error("Request failed.");
      }
    } catch (error) {
      console.error("Error during Sending request", error);
    }
  };

  const fetchReplies = async (contact_id) => {
    setLoading(true);
    try {
      const response = await fetchApi(
        `/startup_entities/${slug}/contact_messages/${contact_id}/contact_messages_replies`,
        "GET"
      );

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        setReplies(res.replies);
      } else {
        const msg = (await response.json()).error;
        // setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (user) => {
    setLoading(true);
    console.log(user);
    try {
      const response = await fetchApi(
        `/startup_entities/${slug}/contact_messages${
          user ? "/user_contact_messages" : ""
        }`,
        "GET"
      );

      if (response.ok) {
        const res = await response.json();
        console.log(res)
        if (!user) {
          setMessages(res.unread_messages);
          setOldMessages(res.read_messages);
        } else setUserMessage(res.user_contact_messages[0]);
        open();
      } else {
        const msg = (await response.json()).error;
        // setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {opened && (
        <Modal
          opened={opened}
          onClose={() => {
            setNotification(null);
            close();
          }}
          size="xl"
          radius="md"
          transitionProps={{ transition: "fade", duration: 200 }}
          className="p-5"
          withCloseButton={false}
        >
          {isMember ? (
            <Stack spacing={20} px={15}>
              <div>
                {" "}
                <Title order={4} className="text-gray-700 mb-5  ">
                  {isMember ? "New Messages" : "Send a message to the team"}
                </Title>
                <Stack spacing={20} pb={15}>
                  {!messages.length && (
                    <Text className="px-2 text-base text-gray-600">
                      No unread messages
                    </Text>
                  )}

                  {messages.map(({ contact_message }) => (
                    <React.Fragment key={contact_message.id}>
                      <MessageItem
                        {...contact_message.user}
                        {...contact_message}
                        handleChaSelect={handleChaSelect}
                      />
                      <hr />
                      <Collapse
                        key={contact_message.id}
                        in={contact_message.id === selectedChat}
                      >
                        <div className="max-h-[400px] overflow-y-auto">
                          {" "}
                          <Stack>
                            {replies.map((reply) => (
                              <React.Fragment key={reply.id}>
                                <MessageItem {...reply.user} {...reply} />
                                <hr />
                              </React.Fragment>
                            ))}
                          </Stack>
                        </div>
                        {role < 1 && (
                          <Textarea
                            placeholder="Hello..."
                            mx={10}
                            my={4}
                            autosize
                            minRows={1}
                            maxRows={3}
                            value={reply}
                            onChange={(event) =>
                              setReply(event.currentTarget.value)
                            }
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                handleReply();
                              }
                            }}
                          />
                        )}
                      </Collapse>
                    </React.Fragment>
                  ))}
                </Stack>
              </div>
              <div>
                <Title order={4} className="text-gray-700 mb-5  ">
                  Old Messages
                </Title>
                <Stack spacing={20} pb={15}>
                  {!oldMessages.length && (
                    <Text className="px-2 text-base text-gray-600">
                      No previous messages
                    </Text>
                  )}
                  {oldMessages.map(({ contact_message }) => (
                    <React.Fragment key={contact_message.id}>
                      <MessageItem
                        {...contact_message.user}
                        {...contact_message}
                        handleChaSelect={handleChaSelect}
                      />
                      <hr />
                      <Collapse
                        key={contact_message.id}
                        in={contact_message.id === selectedChat}
                      >
                        <div className="max-h-[400px] overflow-y-auto">
                          {" "}
                          <Stack>
                            {replies.map((reply) => (
                              <React.Fragment key={reply.id}>
                                <MessageItem {...reply.user} {...reply} />
                                <hr />
                              </React.Fragment>
                            ))}
                          </Stack>
                        </div>
                        {role < 1 && (
                          <Textarea
                            placeholder="Hello..."
                            mx={10}
                            my={4}
                            autosize
                            minRows={1}
                            maxRows={3}
                            value={reply}
                            onChange={(event) =>
                              setReply(event.currentTarget.value)
                            }
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                handleReply();
                              }
                            }}
                          />
                        )}
                      </Collapse>
                    </React.Fragment>
                  ))}
                </Stack>
              </div>
            </Stack>
          ) : (
            <Stack p={20}>
              {notification && <Notification {...notification} />}
              <Title order={4} className="text-gray-700 mb-5  ">
                Messages
              </Title>
              {userMessage && (
                <>
                  <>
                    <MessageItem {...userMessage.user} {...userMessage} />
                    <hr />
                  </>
                  {userMessage.contact_messages_replies &&
                    userMessage.contact_messages_replies.map((reply) => (
                      <React.Fragment key={reply.id}>
                        <MessageItem {...reply.user} {...reply} />
                        <hr />
                      </React.Fragment>
                    ))}
                </>
              )}
              <Textarea
                placeholder="Write something..."
                autosize
                minRows={2}
                maxRows={3}
                value={message_content}
                onChange={(event) =>
                  setMessageContent(event.currentTarget.value)
                }
              />

              <Button
                onClick={handleSend}
                variant="default"
                fullWidth
                className="w-full px-12"
                disabled={!message_content}
              >
                Send message
              </Button>
            </Stack>
          )}
        </Modal>
      )}

      <FeatureButton
        hidePlus={true}
        buttonText={isMember ? t("startups_page.view_messages") : "Contact"}
        action={() => fetchData(!isMember)}
      />
    </>
  );
}
