import {
  Avatar,
  Grid,
  Group,
  List,
  Loader,
  RingProgress,
  Stack,
  Text,
  ThemeIcon,
  Title,
  rem,
} from "@mantine/core";
import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import fetchApi from "../../Lib/api";
import { CreateAction, DropDownSelect } from "./Startups";
import { useLocation } from "react-router-dom";
import { IconCircleCheck, IconUsersGroup } from "@tabler/icons-react";
import moment from "moment";
import { ResponsiveContainer, AreaChart, Area } from "recharts";
import useUserStore from "../../../stores/userStore";
import { useQuery } from "@tanstack/react-query";

const MembersFilters = ({ roles, handleFilterChange }) => {
  const location = useLocation();

  // Memoize the selectData props for DropDownSelect components
  const memoizedSelectData = useMemo(
    () => ({
      deadlines: [],
      progress: [{ label: "Most Progress" }, { label: "Least Progress" }],
      roles: roles.map((r) => ({ label: r })),
      joinDate: [],
      assignedTasks: [],
    }),
    []
  );

  return (
    <section className="flex justify-center">
      <div className="max-w-7xl w-full p-6">
        <Stack spacing={20}>
          <Group position="apart">
          <Group>
            <ThemeIcon
              variant="gradient"
              size="lg"
              aria-label="Gradient action icon"
              gradient={{ from: "blue", to: "rgba(8, 0, 18, 1)", deg: 90 }}
            >
              <IconUsersGroup size={20} />
            </ThemeIcon>
            <Title order={3} className="text-gray-600">
              My Workspace
            </Title>
          </Group>
          <CreateAction />
          </Group>
          
          <Group className="grid grid-cols-5 gap-4 w-full">
            <DropDownSelect
              filterKey="deadlines"
              selectType={{ label: "Deadlines" }}
              selectData={memoizedSelectData.deadlines}
              setFilterParams={handleFilterChange}
            />
            <DropDownSelect
              filterKey="progress"
              selectType={{ label: "Progress" }}
              selectData={memoizedSelectData.progress}
              setFilterParams={handleFilterChange}
            />
            <DropDownSelect
              filterKey="role"
              selectType={{ label: "Roles" }}
              selectData={memoizedSelectData.roles}
              setFilterParams={handleFilterChange}
            />
            <DropDownSelect
              filterKey="join_date"
              selectType={{ label: "Join Dates" }}
              selectData={memoizedSelectData.joinDate}
              setFilterParams={handleFilterChange}
              date={true}
            />
            <DropDownSelect
              filterKey="assign_task"
              selectType={{ label: "Assigned Task" }}
              selectData={memoizedSelectData.assignedTasks}
              setFilterParams={handleFilterChange}
            />
          </Group>
        </Stack>
      </div>
    </section>
  );
};

const MemoMembersFilters = memo(MembersFilters);

// Custom hook for fetching startups
const useStartups = (filterParams) => {
  // Convert filterParams to query string
  const params = new URLSearchParams();
  for (const key in filterParams) {
    params.append(`q[${key}]`, filterParams[key]);
  }
  const queryString = params.toString();

  return useQuery({
    queryKey: ['user-startups', queryString], // Query key includes filter params for caching
    queryFn: async () => {
      const response = await fetchApi(
        `/startup_entities/user_member_startup_entities?${queryString}`,
        'GET'
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch startups');
      }

      return response.json();
    },
    retry: 1, // Retry once on failure
    staleTime: 50 * 60 * 1000, // Consider data fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Keep inactive data in cache for 30 minutes
  });
};


export default function Workspace() {
  const [filterParams, setFilterParams] = useState({});

  const roles = [
    "Founding Team",
    "Contributor", 
    "Intern/Volunteer",
    "Consultant",
    "Investor",
  ];

  const role = (index) => roles[index];

  const { 
    data, 
    isLoading, 
    isError, 
    error 
  } = useStartups(filterParams);

  if (isLoading) {
    return (
      <Loader
        className="mx-auto mt-10"
        color="indigo"
        size="lg"
        variant="dots"
      />
    );
  }

  if (isError) {
    return (
      <div className="flex justify-center items-center h-screen text-red-500">
        Error: {error.message}
      </div>
    );
  }

  return (
    <main>
      <MemoMembersFilters 
        roles={roles} 
        handleFilterChange={setFilterParams} 
      />
      <section className="flex justify-center">
        <div className="max-w-7xl w-full px-3 lg:p-6">
          <Stack spacing={16}>
            {data?.startup_entities.map(
              ({
                startup_entity: {
                  id,
                  description,
                  name,
                  entity_type,
                  slug,
                },
                openings_length,
                members,
                member_productivity,
                response_fill_percentage,
                team_role,
                membership_start_date,
                assigned_categories,
                unread_contact_messages_count,
                pending_join_requests,
              }) => (
                <div
                  key={id}
                  className="group flex flex-col h-full bg-white border border-gray-200 shadow-sm rounded-xl"
                >
                  <div className="p-4 md:p-6">
                    <Grid columns={24}>
                      <Grid.Col span={10}>
                        <Group noWrap position="apart" align="end">
                          <a
                            href={`${
                              entity_type === "Project"
                                ? "/project_entities"
                                : "/startup_entities"
                            }/${slug}`}
                          >
                            <Stack spacing={5}>
                              {entity_type === "Project" ? (
                                <span className="block mb-1 text-xs font-semibold uppercase text-amber-500">
                                  Project
                                </span>
                              ) : (
                                <span className="block mb-1 text-xs font-semibold uppercase text-blue-600">
                                  Startup
                                </span>
                              )}
                              <Stack spacing={0}>
                                <h3 className="text-xl font-semibold line-clamp-2">
                                  {name}
                                </h3>
                                <Text
                                  c="dimmed"
                                  size="xs"
                                  tt="uppercase"
                                  fw={700}
                                >
                                  {role(team_role)} since{" "}
                                  {moment(membership_start_date).format(
                                    "MMMM D, YYYY"
                                  )}
                                </Text>
                              </Stack>
                              <p className="mt-3 text-gray-500 line-clamp-2">
                                {description}
                              </p>
                            </Stack>
                          </a>
                          <RingProgress
                            roundCaps
                            thickness={5}
                            sections={[
                              {
                                value: response_fill_percentage,
                                color: "blue",
                              },
                            ]}
                            label={
                              <Text c="blue" fw={700} ta="center" size="xl">
                                {response_fill_percentage}%
                              </Text>
                            }
                          />
                        </Group>
                      </Grid.Col>
                      <Grid.Col className="h-full" span={7}>
                        <MembersProductivity
                          members={members}
                          member_productivity={member_productivity}
                        />
                      </Grid.Col>
                      <Grid.Col span={7}>
                        <Stack spacing={10}>
                          <Group noWrap position="right">
                            <button
                              type="button"
                              className="py-1 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50"
                            >
                              Requests / Messages
                              <span className="inline-flex items-center py-0.5 px-1.5 rounded-full text-xs font-medium bg-blue-500 text-white">
                                {unread_contact_messages_count +
                                  pending_join_requests}
                              </span>
                            </button>
                          </Group>
                          <Stack spacing={10}>
                            <p className="mt-1 text-sm text-gray-500 dark:text-neutral-500">
                              Pending tasks
                            </p>
                            <List
                              spacing="xs"
                              size="xs"
                              center
                              icon={
                                <ThemeIcon color="teal" size={16} radius="xl">
                                  <IconCircleCheck
                                    style={{ width: '12px', height: '12px' }}
                                  />
                                </ThemeIcon>
                              }
                            >
                              {assigned_categories.map((task) => (
                                <List.Item key={task.id}>
                                  {`${task.name} to be completed before ${task.end_date}`}
                                </List.Item>
                              ))}
                            </List>
                          </Stack>
                        </Stack>
                      </Grid.Col>
                    </Grid>
                  </div>
                </div>
              )
            )}
          </Stack>
        </div>
      </section>
    </main>
  );
}

const MembersProductivity = ({ members, member_productivity }) => {
  const {
      user: { id }
  } = useUserStore((state) => state.user);
  const [memId, setMemId] = useState(id);

  return (
    <Stack justify="space-between" className="h-full">
      <Group spacing={5} className="mt-auto mx-auto">
        {members.map((m) => (
          <Avatar
            key={`user_${m.id}_${m.first_name}`}
            src={m.avatar_url}
            radius="xl"
            color="indigo"
            onMouseEnter={() => setMemId(m.id)}
            onMouseLeave={() => setMemId(id)}
            className="cursor-pointer"
          >
            {m.first_name[0]}
            {m.last_name[0]}
          </Avatar>
        ))}
      </Group>
      <ResponsiveContainer width="100%" height={70}>
        <AreaChart data={member_productivity[memId]}>
          <Area
            type="linear"
            dataKey="up"
            stroke="#1c8cdc"
            fill="#1c8cdc"
            fillOpacity={0.3}
          />
          <Area
            type="linear"
            dataKey="ap"
            stroke="#82ca9d"
            fill="#82ca9d"
            fillOpacity={0.1}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Stack>
  );
};
