import React from 'react';
import useUserStore from '../stores/userStore';

const MicrosoftSignInButton = () => {
    const { access_token    } = useUserStore((state) => state.user);

  const handleSignIn = () => {
    // Get the current page URL
    const currentUrl = window.location.href;

    // Create a hidden form to submit the POST request
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = '/users/auth/microsoft_graph_auth';

    // Add the CSRF token if needed (Rails requires it for POST requests)
    const csrfToken = document.querySelector("meta[name='csrf-token']")?.getAttribute('content');
    if (csrfToken) {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'authenticity_token';
      input.value = csrfToken;
      form.appendChild(input);
    }

    // Add the current URL to the form (redirect_to)
    const redirectToInput = document.createElement('input');
    redirectToInput.type = 'hidden';
    redirectToInput.name = 'redirect_to';
    redirectToInput.value = currentUrl;
    form.appendChild(redirectToInput);

    // Append the form to the body and submit it
    document.body.appendChild(form);
    form.submit();
  };

  return (
    <button
  onClick={handleSignIn}
  disabled={access_token}
  className="flex items-center justify-between px-4 py-2.5 bg-white text-gray-900 font-semibold rounded border border-gray-200 hover:opacity-90 transition-opacity"
>
  <div className="flex items-center">
    <svg className="w-5 h-5 mr-2" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0h11v11H10V0z" fill="#F25022" />
      <path d="M0 0h11v11H0V0z" fill="#00A4EF" />
      <path d="M10 10h11v11H10V10z" fill="#7FBA00" />
      <path d="M0 10h11v11H0V10z" fill="#FFB900" />
    </svg>
    {access_token ? "Teams Connected" : "Connect Teams"}
  </div>
  {access_token && (
    <svg
      className="w-5 h-5 text-green-500"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
    </svg>
  )}
</button>

  );
};

export default MicrosoftSignInButton;
