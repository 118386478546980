import React, { useState, useEffect, useRef, useCallback } from "react";
import debounce from "lodash.debounce";
import styled from "styled-components";
import EditorJS from "@editorjs/editorjs";
import LinkTool from "@editorjs/link";
import CodeTool from "@editorjs/code";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";
import Marker from "@editorjs/marker";
import Table from "@editorjs/table";
import useStartupStore from "../stores/startupStore";
import ChatDocumentUpload from "./ChatDocumentUpload";
import GuideEditor from "./GuideEditor";

const AlignmentTuneTool = require("editorjs-text-alignment-blocktune");

const Container = styled.div`
  display: flex;
  background-color: #222;
  height: 100%;
  position: relative;
  min-width: 100%;
  min-height: 350px;
  overflow: hidden;
`;

const TextAreaContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
  padding: 4px;
  position: relative;
  border-radius: 6px;
  height: 100%;
`;

const EditorContainer = styled.div`
  width: 100%;
  height: 380px;
  background-color: var(--color-background-light);
  padding: 10px;
  padding-left: 24px;
  border-radius: 4px;
  font-size: 12px;
  overflow-y: auto;
`;

const VisibilityOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: absolute;
  bottom: 10px;
  left: 5%;
  z-index: 100;
`;

const VisibilityLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: var(--font-size-body-1);
  color: var(--color-navigation-icon-dark);
  cursor: pointer;y
`;

const VisibilityText = styled.p`
  color: var(--color-navigation-icon-dark);
  font-size: var(--font-size-h5);
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const TextCreationArea = ({ setMessage, loading, setLoading }) => {
  const editorInstance = useRef(null);
  const editorHolderRef = useRef(null);
  const guideEditorRef = useRef(null);
  const [localPrivacy, setLocalPrivacy] = useState("Everyone");
  const [editMode, setEditMode] = useState(false);

  const {
    content,
    setContent,
    privacy,
    setPrivacy,
    selectedProgram,
    setEditorState,
  } = useStartupStore((state) => ({
    content: state.content,
    setContent: state.setContent,
    privacy: state.privacy,
    setPrivacy: state.setPrivacy,
    selectedProgram: state.selectedProgram,
    setEditorState: state.setEditorState,
  }));

  const pageId = selectedProgram.cid;
  const index = selectedProgram.sid;
  const pageRefs = useRef({ prevId: pageId, prevIndex: index });

  const debouncedPrivacyUpdate = useCallback(
    debounce((pageId, index, value) => {
      setPrivacy(pageId, index, value);
    }, 300),
    [setPrivacy]
  );

  const saveEditorContent = useCallback(
    debounce(async () => {
      if (!editorInstance.current) return;

      try {
        const savedContent = await editorInstance.current.save();
        setContent(pageId, index, savedContent);
      } catch (error) {
        console.error("Failed to save editor content:", error);
      }
    }, 1000),
    [pageId, index, setContent]
  );

  const getCsrfToken = () => {
    const metaTag = document.querySelector("meta[name='csrf-token']");
    return metaTag ? metaTag.content : "";
  };

  const initializeEditor = useCallback(() => {
    if (editorInstance.current || !editorHolderRef.current) return;

    const csrfToken = getCsrfToken();

    editorInstance.current = new EditorJS({
      holder: editorHolderRef.current,
      placeholder: "Start working here...",
      data: content[pageId]?.[index]?.response || { blocks: [] },
      tools: {
        header: {
          class: Header,
          tunes: ["anyTuneName"],
        },
        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3,
            withHeadings: true,
          },
        },
        Marker: {
          class: Marker,
          shortcut: "CMD+SHIFT+M",
        },
        linkTool: {
          class: LinkTool,
          config: {
            endpoint: "/api/fetch_url",
            headers: {
              "X-CSRF-Token": csrfToken,
            },
          },
        },
        list: {
          class: List,
        },
        paragraph: {
          class: Paragraph,
          tunes: ["anyTuneName"],
          config: {
            inlineToolbar: true,
          },
        },
        anyTuneName: {
          class: AlignmentTuneTool,
          config: {
            default: "left",
            blocks: {
              header: "center",
              list: "right",
            },
          },
        },
        code: {
          class: CodeTool,
        },
      },
      readOnly:
        content[pageId]?.[index]?.wait &&
        content[pageId]?.[index]?.diff_proposed_response,
      onChange: saveEditorContent,
    });
  }, [pageId, index, content, saveEditorContent]);

  const handlePrivacyChange = useCallback(
    (value) => {
      setLocalPrivacy(value);
      debouncedPrivacyUpdate(pageId, index, value);
    },
    [pageId, index, debouncedPrivacyUpdate]
  );

  useEffect(() => {
    if (!content[pageId]) {
      setEditorState();
    }
    initializeEditor();
    setLocalPrivacy(privacy[pageId]?.[index] || "Everyone");

    return () => {
      saveEditorContent.cancel();
      debouncedPrivacyUpdate.cancel();
      if (editorInstance.current) {
        editorInstance.current.destroy();
        editorInstance.current = null;
      }
    };
  }, []);

  useEffect(() => {
    const syncContent = async () => {
      if (!editorInstance.current) return;

      const { prevId, prevIndex } = pageRefs.current;

      if (pageId !== prevId || index !== prevIndex) {
        try {
          const savedContent = await editorInstance.current.save();
          setContent(prevId, prevIndex, savedContent);

          await editorInstance.current.clear();
          await editorInstance.current.render(
            content[pageId]?.[index]?.response || { blocks: [] }
          );

          pageRefs.current = { prevId: pageId, prevIndex: index };
          setLocalPrivacy(privacy[pageId]?.[index] || "Everyone");
        } catch (error) {
          console.error("Failed to sync content:", error);
        }
      }
    };

    syncContent();
  }, [pageId, index]);

  return (
    <FlexColumnContainer>
      <Container>
        <TextAreaContainer>
          <EditorContainer>
            <div ref={editorHolderRef} />
          </EditorContainer>
          <VisibilityOptions>
            <VisibilityText>Visible to</VisibilityText>
            {["Everyone", "Members", "Only me"].map((option) => (
              <VisibilityLabel key={option}>
                <input
                  type="radio"
                  checked={localPrivacy === option}
                  onChange={() => handlePrivacyChange(option)}
                  name={`visibility-${pageId}-${index}`}
                  style={{ width: "unset" }}
                />
                {option}
              </VisibilityLabel>
            ))}
          </VisibilityOptions>
        </TextAreaContainer>
        <GuideEditor editorRef={guideEditorRef} />
      </Container>
      <ChatDocumentUpload
        setMessage={setMessage}
        loading={loading}
        setLoading={setLoading}
        name={content[pageId]?.[index]?.name}
        close={() => setEditMode(false)}
      />
    </FlexColumnContainer>
  );
};

export default React.memo(TextCreationArea);
