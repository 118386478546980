import React, { useState } from "react";
import {
  Avatar,
  Button,
  Card,
  Group,
  Loader,
  Modal,
  Select,
  Stack,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import fetchApi from "../Lib/api";
import plusIcon from "../../stories/assets/add.svg"
import Notification from "../Lib/Notification";
import { IconChevronRight, IconSearch } from "@tabler/icons-react";
import FeatureButton from "../../stories/FeatureButton";
import useStartupStore from "../../stores/startupStore";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const AddMember = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-button-accent);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointter;
`;

export function InviteMembers() {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [opened, { open, close }] = useDisclosure(false);
  const [roleOffered, setRoleOffered] = useState("");
  const [reason, setReason] = useState("");
  const [notification, setNotification] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const { t } = useTranslation();
  const isSubdomain = window.location.hostname.split('.').length > 2;


  const { slug, isMember, isOwner, existingJoinRequest, id } = useStartupStore(
    (state) => ({
      id: state.id,
      slug: state.slug,
      isOwner: state.isOwner,
      isMember: state.isMember,
      existingJoinRequest: state.existingJoinRequest,
    })
  );

  const handleChange = async (val) => {
    console.log(val);
    setValue(val);
    setData([]);

    if (!val.trim().length || val.trim().length < 3) {
      setLoading(false);
    } else {
      setLoading(true);
      try {
        const response = await fetch(`/api/users/search?search=${val}`);
        const users = await response.json();

        setData(users);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSubmit = async (email) => {
    try {
      const response = await fetchApi("/invitations", "POST", {
        email: email,
        inviteable_type: "StartupEntity",
        inviteable_id: id,
      });

      if (response.ok) {
        const msg = (await response.json()).message;
        setNotification({ type: "success", content: msg });
        console.log("Invited successful!");
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error("Invitation failed.");
      }
    } catch (error) {
      console.error("Error during Invitation", error);
    }
  };

  const handleInvite = async () => {
    try {
      const response = await fetchApi(
        `/startup_entities/${slug}/join_requests/create_for_startup`,
        "POST",
        {
          join_request: { reason, role: roleOffered },
        }
      );

      if (response.ok) {
        const msg = (await response.json()).message;
        setNotification({ type: "success", content: msg });
        setDisableButton(true);
        console.log("Request sent successful!");
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error("Request failed.");
      }
    } catch (error) {
      console.error("Error during Sending request", error);
    }
  };

  const sendUserInvite = async () => {
    try {
      const response = await fetchApi("/users/invitation", "POST", {
        user: { email: value },
        startup_id: id,
      });

      if (response.ok) {
        const msg = (await response.json()).message;
        setNotification({ type: "success", content: msg });
        console.log("Request sent successful!");
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error("Request failed.");
      }
    } catch (error) {
      console.error("Error during Sending request", error);
    }
  };

  return (
    <>
      {opened && (
        <Modal.Root
          opened={opened}
          onClose={() => {
            setValue("");
            setNotification(null);
            close();
          }}
          size="lg"
          padding={0}
          withCloseButton={false}
        >
          <Modal.Overlay />
          <Modal.Content className="ml-[10vh]">
            <Modal.Body>
              <Card radius="md" padding={0}>
                {notification && <Notification {...notification} />}
                {isOwner || isMember ? (
                  <>
                    <Card.Section withBorder inheritPadding py="xs">
                      <TextInput
                        icon={<IconSearch />}
                        variant="unstyled"
                        placeholder={t("startups_page.invite.placeholder")}
                        // value={value}
                        onChange={(event) =>
                          handleChange(event.currentTarget.value)
                        }
                        rightSection={loading ? <Loader size="1rem" /> : null}
                      />
                    </Card.Section>
                    <ul>
                      {data.map(
                        ({
                          first_name,
                          last_name,
                          job_title,
                          avatar_url,
                          email,
                        }) => (
                          <li className="p-4 hover:bg-gray-200 mb-4 cursor-pointer">
                            <Group noWrap onClick={() => handleSubmit(email)}>
                              <Avatar src={avatar_url} radius="xl" />

                              <div style={{ flex: 1 }}>
                                <Text size="sm" weight={500}>
                                  {first_name} {last_name}
                                </Text>

                                <Text color="dimmed" size="xs">
                                  {job_title}
                                </Text>
                              </div>

                              <IconChevronRight size="1rem" />
                            </Group>
                          </li>
                        )
                      )}
                    </ul>

                    {!data.length &&
                      value &&
                      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) && (
                        <>
                          <Card.Section withBorder inheritPadding py="xs">
                            <Text className="text-center">
                            {t("startups_page.invite.feedback_s")} {value}. {t("startups_page.invite.feedback_e")}
                            </Text>
                          </Card.Section>
                          <Button
                            onClick={sendUserInvite}
                            variant="default"
                            fullWidth
                            className="px-12"
                          >
                            {t("startups_page.invite.invite")} {value}
                          </Button>
                        </>
                      )}
                  </>
                ) : (
                  <Stack className="p-4">
                    <Textarea
                      label={t("startups_page.roles.m_a_reason")}
                      placeholder="Hello..."
                      autosize
                      minRows={3}
                      maxRows={5}
                      value={reason}
                      onChange={(event) => setReason(event.currentTarget.value)}
                    />
                    <Select
                      label={t("startups_page.roles.m_s_title")}
                      data={[
                        "Founding Team",
                        "Contributor",
                        "Intern/Volunteer",
                        "Consultant",
                        "Investor",
                      ]}
                      value={roleOffered}
                      description={t("startups_page.roles.m_s_desc")}
                      placeholder="e.g., Co-founder"
                      onChange={setRoleOffered}
                      withinPortal={true}
                    />
                    {!notification ? (
                      <Button
                        onClick={handleInvite}
                        variant="default"
                        fullWidth
                        className="w-full px-12"
                        disabled={!reason}
                      >
                        {t("startups_page.roles.m_a_request")}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          setNotification(null);
                          close();
                        }}
                        variant="default"
                        fullWidth
                        className="w-full px-12"
                        disabled={!reason}
                      >
                        {t("startups_page.roles.m_a_close")}
                      </Button>
                    )}
                  </Stack>
                )}
              </Card>
            </Modal.Body>
          </Modal.Content>
        </Modal.Root>
      )}
      <>
        {isOwner || isMember ? (
           <AddMember onClick={open}>
           <img src={plusIcon} alt="Add Member" />
         </AddMember>
        ) : (
          <FeatureButton
            action={open}
            buttonText={`${
              disableButton || existingJoinRequest
                ? t("startups_page.members.request_sent")
                : t("startups_page.members.request_join")
            }`}
          />
        )}
      </>
    </>
  );
}
