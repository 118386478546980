import {
  UnstyledButton,
  Menu,
  Image,
  rem,
  Button,
  Group,
  Stack,
  Title,
  createStyles,
  ScrollArea,
  SimpleGrid,
  ThemeIcon,
  Modal,
  Loader,
} from "@mantine/core";
import {
  IconAward,
  IconBrain,
  IconBulb,
  IconChevronDown,
  IconExternalLink,
  IconRocket,
} from "@tabler/icons-react";

import React, { useContext, useEffect, useMemo, useState } from "react";

import StartupCard from "./Card";
import fetchApi from "../../Lib/api";
import {
  countries,
  industries,
  sorting,
  stages,
  tags,
} from "../../Onboarding/data";
import { useDisclosure } from "@mantine/hooks";
import { DatePicker } from "@mantine/dates";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useUserStore from "../../../stores/userStore";
import { useQuery } from "@tanstack/react-query";

const useStyles = createStyles((theme) => ({
  control: {
    minWidth: rem(150),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: [theme.spacing.xs, theme.spacing.md],
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[4]}`,
    transition: "background-color 150ms ease",
    backgroundColor: "white",
    "&[data-expanded]": {
      backgroundColor: theme.colors.gray[2],
    },
    "&:hover": {
      backgroundColor: theme.colors.gray[2],
    },
  },
  label: {
    fontWeight: 600,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[6],
    marginRight: theme.spacing.md,
  },
  icon: {
    transition: "transform 150ms ease",
    transform: "rotate(0deg)",
    "[data-expanded] &": {
      transform: "rotate(180deg)",
    },
  },
}));

export function DropDownSelect({
  selectType,
  selectData,
  setFilterParams,
  filterKey,
  date,
}) {
  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState(selectType);
  const { classes, cx } = useStyles();

  const handleSelect = (item) => {
    if (item.label === "All") {
      setSelected(selectType);
      setFilterParams((prevParams) => {
        const { [filterKey]: _, ...updatedParams } = prevParams;

        return updatedParams;
      });
      return;
    }
    setSelected(item);
    setFilterParams((p) => ({ ...p, [filterKey]: item.value || item.label }));
    console.log({ [filterKey]: item.label });
  };

  const items = selectData.map((item, index) => (
    <Menu.Item onClick={() => handleSelect(item)} key={item.label + index}>
      {item.label}
    </Menu.Item>
  ));

  return (
    <Menu
      style={{ backgroundColor: "white", minWidth: "100%" }}
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      radius="md"
      width={date ? "unset" : "target"}
      withArrow
      withinPortal
    >
      <Menu.Target>
        <UnstyledButton
          className={classes.control}
          style={{ backgroundColor: "white" }}
        >
          <Group gap="xs">
            <span className={classes.label}>{selected.label}</span>
          </Group>
          <IconChevronDown size="1rem" className={classes.icon} stroke={1.5} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown w={500}>
        {!date ? (
          <>
            <Menu.Label>{selected.label}</Menu.Label>
            <ScrollArea h={200}> {items}</ScrollArea>
          </>
        ) : (
          <Stack className="text-center">
            <Button
              variant="light"
              compact
              onClick={(d) => handleSelect({ label: "All" })}
            >
              Reset
            </Button>
            <DatePicker
              onChange={(d) =>
                handleSelect({ label: moment(d).format("YYYY-MM-DD") })
              }
            />
          </Stack>
        )}
      </Menu.Dropdown>
    </Menu>
  );
}

export function InputWithButton({ placeholder, setFilterParams, buttonText }) {
  const [value, setValue] = useState("");
  return (
    <div className="relative w-full lg:w-2/3 ">
      <div className="absolute inset-y-0 start-0 flex items-center ps-4 pointer-events-none">
        <svg
          className="w-8 h-8 text-gray-500"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
          />
        </svg>
      </div>
      <input
        type="search"
        id="default-search"
        className="block w-full p-4 ps-16 text-xl text-gray-900 border-none outline-none rounded-lg  focus:ring-red-500 focus:border-red-500"
        placeholder={placeholder}
        required
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.target.value.trim() === "") {
            setFilterParams((prevParams) => {
              const { search_term: _, ...updatedParams } = prevParams;

              return updatedParams;
            });
            return;
          }
          if (e.key === "Enter" && e.target.value.trim() !== "") {
            setFilterParams((p) => ({
              ...p,
              search_term: e.target.value.trim(),
            }));
          }
        }}
      />
      <button
        onClick={(e) => {
          if (value.trim() === "") {
            setFilterParams((prevParams) => {
              const { search_term: _, ...updatedParams } = prevParams;

              return updatedParams;
            });
            return;
          }
          setFilterParams((p) => ({
            ...p,
            search_term: value.trim(),
          }));
        }}
        className="text-white absolute end-2.5 inset-y-2.5 bg-blue-800 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 "
      >
        {buttonText}
      </button>
    </div>
  );
}

export function CreateAction({}) {
  const [opened, { close, open }] = useDisclosure(false);
  const { user } = useUserStore((state) => state.user);
  const { t } = useTranslation();

  return (
    <>
      <Modal opened={opened} onClose={close} size="lg" withCloseButton={false}>
        <div class="p-4 overflow-y-auto">
          <p class="mt-1 text-gray-8000">{t("modal.profile_access_message")}</p>
        </div>
        <div class="flex justify-end items-center gap-x-2 py-3 px-4">
          <Button onClick={close} variant="default" className=" px-12">
            {t("modal.cancel_button")}
          </Button>
          <Button
            href="/onboarding"
            component="a"
            variant="light"
            color="indigo"
            className="text-blue-700 px-12"
          >
            {t("modal.complete_profile_button")}
          </Button>
        </div>
      </Modal>
      <Group className="flex-wrap lg:flex-nowrap">
        <Button
          component={
            user.profile_complete || !user.validated_once ? "a" : "button"
          }
          href={
            user.profile_complete || !user.validated_once
              ? "/ai_validation_tool"
              : undefined
          }
          onClick={
            !user.profile_complete && user.validated_once ? open : undefined
          }
          variant="outline"
          fullWidth
          className="w-full font-light rounded-lg border border-transparent bg-blue-800 text-white hover:bg-blue-600 disabled:opacity-50 disabled:pointer-events-none create-startup"
          leftIcon={<IconBrain size="1.5rem" />}
          size="xl"
        >
          {t("startups.validate_and_create")}
        </Button>
        <Button
          variant="default"
          fullWidth
          className="w-full lg:w-1/2 flex-grow-0 font-light border border-transparent bg-blue-600 text-white hover:bg-blue-600 disabled:opacity-50 disabled:pointer-events-none"
          leftIcon={<IconRocket size="1.5rem" />}
          size="xl"
          component={user.profile_complete ? "a" : "button"}
          href={user.profile_complete ? "/project_entities/new" : undefined}
          onClick={!user.profile_complete ? open : undefined}
        >
          {t("startups.create_project")}
        </Button>
      </Group>
    </>
  );
}

const useFilteredStartups = (filterParams) => {
  // Convert filterParams to query string
  const params = new URLSearchParams();
  for (const key in filterParams) {
    params.append(`q[${key}]`, filterParams[key]);
  }
  const queryString = params.toString();

  return useQuery({
    queryKey: ['filtered-startups', queryString], // Query key includes filter params
    queryFn: async () => {
      const response = await fetchApi(`/startup_entities?${queryString}`, 'GET');

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch startups');
      }

      return await response.json();
    },
    retry: 1, // Retry once on failure
    staleTime: 5 * 60 * 1000, // Data is fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Cache inactive data for 30 minutes
  });
};

export default function Startups() {
  const [startups, setStartups] = useState([]);
  const [similarStartups, setSimilarStartups] = useState([]);
  const [searchStartups, setSearchStartups] = useState([]);
  const [filterParams, setFilterParams] = useState({});

  const { t, i18n } = useTranslation();

  const memoizedSelectData = useMemo(
    () => ({
      countries: [{ label: "All", value: "All" }, ...countries],
      industries: [{ label: "All", value: "All" }, ...industries],
      tags: [{ label: "All", value: "All" }, ...tags],
      stages: [{ label: "All", value: "All" }, ...stages],
      sorting: [{ label: "All", value: "All" }, ...sorting],
    }),
    []
  );



  const { data, isLoading, isError, error } = useFilteredStartups(filterParams);

  useEffect(() => {
    console.log(data)
    if (data) {
      setStartups(data['All Startups'] || []);
      setSimilarStartups(data['Similar Startups'] || []);
      setSearchStartups(data['Exact Matches'] || []);
    }
  }, [data, setStartups, setSimilarStartups, setSearchStartups]);



  if (isLoading) {
    return (
      <Loader
        className="mx-auto mt-10"
        color="indigo"
        size="lg"
        variant="dots"
      />
    );
  }


  if (isError) {
    return <div>Error: {error.message}</div>;
  }


  return (
    <>
      <main className="flex justify-center ">
        <div className="max-w-7xl w-full p-6">
          <Stack spacing={12}>
            <Group>
              <ThemeIcon
                variant="gradient"
                size="lg"
                aria-label="Gradient action icon"
                gradient={{ from: "blue", to: "rgba(8, 0, 18, 1)", deg: 90 }}
              >
                <IconBulb />
              </ThemeIcon>
              <Title order={3} className="text-slate-900">
                {t("startups.section_title")}
              </Title>
            </Group>
            <Group className="flex-wrap lg:flex-nowrap">
              <InputWithButton
                placeholder={t("startups.search_placeholder")}
                buttonText={t("startups.search_button")}
                setFilterParams={setFilterParams}
              />

              <Button
                component="a"
                href="/dashboard/#/members?q=expert"
                variant="default"
                fullWidth
                className="w-full lg:w-1/3 flex-grow-0 font-light rounded-lg border border-transparent bg-green-800 text-white hover:bg-green-600 disabled:opacity-50 disabled:pointer-events-none expert"
                leftIcon={<IconAward size="1.2rem" />}
                size="xl"
              >
                {t("startups.looking_for_expert")}
              </Button>
            </Group>
            <Group
              key={i18n.language}
              className="grid grid-cols-5 gap-4 w-full"
            >
              <DropDownSelect
                filterKey="country_matches"
                selectType={{ label: t("filters.country") }}
                selectData={memoizedSelectData.countries}
                setFilterParams={setFilterParams}
              />
              <DropDownSelect
                filterKey="industry_matches"
                selectType={{ label: t("filters.industry") }}
                selectData={memoizedSelectData.industries}
                setFilterParams={setFilterParams}
              />
              <DropDownSelect
                filterKey="openings_status_matches"
                selectType={{ label: t("filters.collab_type") }}
                selectData={memoizedSelectData.tags}
                setFilterParams={setFilterParams}
              />
              <DropDownSelect
                filterKey="stage_matches"
                selectType={{ label: t("filters.stage") }}
                selectData={memoizedSelectData.stages}
                setFilterParams={setFilterParams}
              />
              <DropDownSelect
                filterKey="sorting"
                selectType={{ label: t("filters.more_filters") }}
                selectData={memoizedSelectData.sorting}
                setFilterParams={setFilterParams}
              />
            </Group>
            <CreateAction />
          </Stack>
        </div>
      </main>
        <main className="flex justify-center">
          <div className="max-w-7xl w-full p-6 all_startups">
            {!searchStartups.length && !similarStartups.length && (
              <Stack spacing={12}>
                <Title order={4} className="text-slate-900">
                  {t("startups.all_startups")}
                </Title>
                <SimpleGrid
                  spacing="lg"
                  verticalSpacing="lg"
                  breakpoints={[
                    { minWidth: "0rem", cols: 1 },
                    { minWidth: "50rem", cols: 2 },
                    { minWidth: "90rem", cols: 3 },
                  ]}
                >
                  {startups.map(
                    ({
                      is_following,
                      startup_entity,
                      openings_length,
                      members,
                    }) => (
                      <StartupCard
                        key={startup_entity.id}
                        startup={startup_entity}
                        isFollowing={is_following}
                        roles={openings_length}
                        members={members}
                      />
                    )
                  )}
                </SimpleGrid>
              </Stack>
            )}
            {!startups.length && !!Object.keys(filterParams).length && (
              <Stack spacing={52}>
                <Stack spacing={12}>
                  <Title order={4} className="text-slate-900">
                    {t("startups.search_results")}
                  </Title>
                  <SimpleGrid
                    spacing="lg"
                    verticalSpacing="lg"
                    breakpoints={[
                      { minWidth: "0rem", cols: 1 },
                      { minWidth: "50rem", cols: 2 },
                      { minWidth: "90rem", cols: 3 },
                    ]}
                  >
                    {searchStartups.map(
                      ({
                        is_following,
                        startup_entity,
                        openings_length,
                        members,
                      }) => (
                        <StartupCard
                          key={startup_entity.id}
                          startup={startup_entity}
                          isFollowing={is_following}
                          roles={openings_length}
                          members={members}
                        />
                      )
                    )}
                  </SimpleGrid>
                </Stack>

                <Stack spacing={12}>
                  <Title
                    order={4}
                    className="text-slate-900 text-center underline underline-offset-4"
                  >
                    {t("startups.similar_startups")}
                  </Title>
                  <SimpleGrid
                    spacing="lg"
                    verticalSpacing="lg"
                    breakpoints={[
                      { minWidth: "0rem", cols: 1 },
                      { minWidth: "50rem", cols: 2 },
                      { minWidth: "90rem", cols: 3 },
                    ]}
                  >
                    {similarStartups.map(
                      ({
                        is_following,
                        startup_entity,
                        openings_length,
                        members,
                      }) => (
                        <StartupCard
                          key={startup_entity.id}
                          startup={startup_entity}
                          isFollowing={is_following}
                          roles={openings_length}
                          members={members}
                        />
                      )
                    )}
                  </SimpleGrid>
                </Stack>
              </Stack>
            )}
          </div>
        </main>
    </>
  );
}
