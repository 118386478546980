import React, { useState, useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import editIcon from "./assets/edit.svg";
import collapseIcon from "./assets/chevronLeft.svg";
import { CategoryWithSubcategories } from "../components/Startups/Timeline";
import PackageSelector from "./PackageSelector";
import Modal from "./Modal";
import emptyImage from "./assets/empty-state.svg";
import useStartupStore from "../stores/startupStore";
import RoadmapPDFGenerator from "../components/Startups/RoadmapPDFGenerator";
import { useRoadmapStore } from "./StartupBody";

const RoadmapListContainer = styled.div`
  min-width: 260px;
  background-color: var(--color-container-card-default);
  border: 1px solid #dee8e7;
  transition: transform 0.8s ease, width 0.8s ease;
  height: fit-content
    ${(props) =>
      props.collapsed === "true" &&
      `
    transform: translateX(0px);
    width: 60px;
    min-width: 60px;
  `};
`;

const RoadmapSection = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: fit-content;
`;

const RoadmapHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-inline: 12px;
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const RoadmapTitle = styled.span`
  font-weight: bold;
  color: #333;
`;

const TaskCount = styled.span`
  color: var(--color-navigation-icon-dark);
  font-weight: var(--font-weight-h4);
`;

const RoadmapListStyled = styled.ul`
  list-style: none;
  overflow-x: hidden;
  padding: 0;
  margin: 0;

  ${(props) =>
    props.editmode === "true" &&
    `
    overflow-y: scroll;
    max-height: 500px;
    justify-content: center;
  `}

  ${(props) =>
    props.collapsed === "true" &&
    `
    overflow-y: hidden;
    justify-content: center;
  `}
`;

const RoadmapFooter = styled.div`
  display: flex;
  gap: 4px;
  padding: 4px;
  text-align: center;
  transition: opacity 0.3s ease;
`;

const EditIcon = styled.img`
  width: 24px;
  height: 24px;
  background-color: var(--color-navigation-icon-dark);
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 40vh;

  img {
    margin-bottom: 20px;
  }
`;

const CreateMapButton = styled.button`
  background-color: var(--color-button-accent);
  color: var(--color-container-card-default);
  padding: 10px 20px;
  border: 1px solid var(--color-button-accent);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4px;

  &:hover {
    background-color: var(--color-container-card-default);
    color: var(--color-button-accent);
    border: 1px solid var(--color-button-accent);
  }

  span {
    margin-right: 5px;
  }
`;

// RoadmapList component with subcategory editing integration
const RoadmapList = ({}) => {
  const {
    setSelectedProgram,
    editMode,
    program,
    setCategory,
    isOwner,
    slug,
    isMember,
    entity,
  } = useStartupStore((state) => ({
    program: state.program,
    setCategory: state.setCategory,
    setSelectedProgram: state.setSelectedProgram,
    editMode: state.editMode,
    isMember: state.isMember,
    isOwner: state.isOwner,
    slug: state.slug,
    entity: state.entity
  }));
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const { opened, setOpened } = useRoadmapStore();

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <RoadmapListContainer editmode={editMode + ""} collapsed={isCollapsed + ""}>

          <RoadmapSection>
            <RoadmapHeader>
              <HeaderContent>
                <MenuButton onClick={toggleCollapse}>
                  <img src={collapseIcon} alt="Menu Icon" />
                </MenuButton>
                {!isCollapsed && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <RoadmapTitle>To Do</RoadmapTitle>
                      {isMember && <EditIcon
                        src={editIcon}
                        alt="Edit Icon"
                        onClick={() => setEnableEdit(!enableEdit)}
                      />}
                    </div>
                    <TaskCount>{program?.categories?.length} tasks</TaskCount>
                  </>
                )}
              </HeaderContent>
            </RoadmapHeader>

            <RoadmapListStyled
              editmode={editMode + ""}
              collapsed={isCollapsed + ""}
            >
              {program?.categories?.map((category, index) => (
                <CategoryWithSubcategories
                  key={`category-${category.id}`}
                  category={category}
                  index={index}
                  setSelectedPrograms={setSelectedProgram}
                  owner={isOwner}
                  member={isMember}
                  enableEdit={enableEdit}
                  setCategory={setCategory}
                  isCollapsed={isCollapsed}
                />
              ))}
            </RoadmapListStyled>
          </RoadmapSection>
          {!isCollapsed && (
            <RoadmapFooter collapsed={isCollapsed + ""}>
              {isOwner && (
                <CreateMapButton onClick={() => setOpened(true)}>
                  New Timeline
                </CreateMapButton>
              )}
              {isMember && <RoadmapPDFGenerator slug={slug} />}
            </RoadmapFooter>
          )}
          <Modal showmodal={opened} setshowmodal={setOpened}>
            <PackageSelector useRoadmapStore={useRoadmapStore} />
          </Modal>

    </RoadmapListContainer>
  );
};

export default RoadmapList;
